import React, {createContext, useReducer} from 'react';
import WeekReducer from '../reducers/week';

const WeekStore = createContext({});

const today = new Date();
const initialState = {
    state: [], filterOptions: [], filter: [], orderValue: { "mainFields": [] }, filterValue:[{
            "stations": "",
            "schedules": [],
            "shift_type": [],
            "driver": [],
            "overtime": [],
            "unscheduled": false
        }],currDate:today,schedulerFooterData:[],refTable:undefined,loading:true, isUserAccessAuthoriseIncident:false};

const WeekProvider = ({children}) => {
    const useWeekState = useReducer(WeekReducer,{
        ...initialState,
        changes: {},
        lastModified: new Date().getTime(),
    })

    return (
        <WeekStore.Provider value={useWeekState}>
            {children}
        </WeekStore.Provider>
    )
}

export {WeekProvider}

export default WeekStore
