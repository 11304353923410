import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import moment from "moment";
import { confirm } from "../../../components/Confirm";
import Driver from "../../../components/People";
import { alert } from "../../../components/Alert";
import { Icon, Spacer, Theme, ThirdTitle } from "@dspworkplace/ui";
import ShiftBlock, { TimeLineTrack } from "../../../components/ShiftBlock";
import ShiftBlockPopup from "../Common/ShiftPopUp";
import Loading from "../../../components/Loading";
import Empty from "../../../components/Empty";
import Store from "./store";
import { calculateShiftSizes, getShiftFormatted, HeaderColumnComponent, ShiftWrapper } from "./helper.js";
import { getCompanyId, useAuth, useLocalStorage, getRole, getAllowsDispatcherPermission, getUserId } from "../../../Auth";
import ES from "../../../EventSource";
import useSchedulerApi from "../Common/api";
import { showFlexibleShiftBlockForm } from "../Week/components/shiftBlockForms";
import toast from "../../../components/Toasts/toast";
import { checkIfShiftAlreadyExist } from "./helper";
import { useSelectedStation } from "../../../components/StationSelector";
import API from "../../SchedulerLoadOut/api";
import Tooltip from "../../../components/Tooltip";
import { getOffset } from "../../../components/TimeFormat";
import Details from "../../Employees/details";
import { dialogPromise , dialog} from "../../../components/Dialog";
import { NoteEditDailog } from "../Week/week";
import { dayDndMoveCopy } from "../Common/dayDndMoveCopy.js";
import { addEditDeletePopup } from "../Common/AddEditDeletePopup.js";
import { isEmptyDayBoxNonSelectable, openCreateMultipleShiftPopup } from "../Common/emptyShiftBoxUtil.js";
import useGhostShiftApi from "../Common/GhostShiftCodeOptimize";
import axios from "axios";

const AutoSizerContainer = styled.div`
    width: 100%;
    height: calc(100vh - 150px);
    display: block;
    border: 1px solid #cccccc;
    border-radius: 2px;

    .dayView {
        flex: 1;

        *:focus {
            outline: none;
        }

        .ReactVirtualized__Table__Grid {
            //will-change: unset !important;
        }

        .ReactVirtualized__Table__headerRow {
            overflow: visible !important;
        }
        .ReactVirtualized__Table__row {
            border-bottom: 1px solid #cccccc;
            overflow: visible !important;
        }
        .ReactVirtualized__Table__rowColumn {
            margin: 0;
            //padding:8px;
            border-right: 1px solid #cccccc;
            height: 100%;
            overflow: visible !important;

            &:last-child {
                border-right: none;
            }
        }

        .ReactVirtualized__Grid__innerScrollContainer {
            overflow: visible !important;
            z-index: 1;
        }

        .ReactVirtualized__Table__headerColumn {
            &:last-child {
                border-right: none !important;
            }
        }
    }
`;

const HeaderStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#707070",
    textTransform: "capitalize",
    fontFamily: "circe-rounded",
    display: "flex",
    alignItems: "center",
    height: "100%",
    borderBottom: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    margin: "0",
};

const HeaderColumn = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const HeaderColumnOptions = styled.ul`
    width: auto;
    padding: 0;
    position: absolute;
    left: ${(props) => (props.direction === "left" ? "-1px" : "auto")};
    right: ${(props) => (props.direction === "right" ? "0px" : "auto")};
    top: 20px;
    list-style: none;
    z-index: 2;
    background: white;
    box-shadow: 0px 0px 6px #cccccc;
    border: 1px solid #cccccc;
    border-radius: 2px;
    box-sizing: border-box;

    > li {
        padding: 0 8px;
        font-size: 14px;
        color: #707070;
        border-radius: 1px 1px 0px 0px;
        display: flex;
        align-items: center;
        height: 32px;
        white-space: nowrap;

        &.title {
            font-size: 12px;
            font-weight: bold;
        }
    }
    > li:not(.title) {
        &:hover {
            background: #eaf0f6;
        }
    }
`;

const ProfileColumn = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .picture {
        // height: 26px;
        // width: 26px;
        // border-radius: 100%;
        // border: 1px solid ${Theme.colors.info.border};
        // background: ${Theme.colors.info.bg};
        // display: flex;
        // justify-content: center;
        // align-items: flex-end;
        position: relative;

        ${(props) =>
        props.tooltip &&
        `
        &:before{
            position : absolute;
            content : attr(data-tooltip);
            opacity : 0;

            font-size:14px;
            background:#EAF0F6;
            color:#777777;
            padding:4px;
            border-radius:4px;
            pointer-events:none;
            z-index: 10;
            left:100%;
            top:0;
            width: 400px;
            overflow: visible;
            text-overflow: none;
            white-space: normal;
        }

        &:hover {
            &:before {
                opacity:1;
            }

        }

        `}
    }

    > .info {
        margin-left: 5px;
        h5 {
            font-size: 12px;
            font-family: circe-rounded;
            color: #333333;
            font-weight: normal;
        }
        > span {
            font-size: 12px;
            color: #707070;
        }
    }
`;

const Hoverable = styled.div`
    > .picture{
        display: none;
    }
    &:hover {
        > .picture{
            display: block;
        }
    }
`;

const sizes = {
    header: 32,
    defaultClose: 44,
    shiftHeight: 32,
    shiftTopPadding: 8,
    backupHeader: 54,
};
const TYPES = {
    open: "OPEN_SHIFT",
    backup: "BACKUP_SHIFT",
    driver: "DRIVERS_SHIFT",
};

const DriverColumnWidth = 185;

const ShiftBlockWrapper = styled.div`
    width: ${(props) => (props.width ? `${props.width}px` : 0)};
    min-width: ${(props) => (props.width ? `${props.width}px` : 0)};
    height: ${sizes.shiftHeight}px;
    // padding-left: ${(props) => (props.left ? `${props.left}px` : 0)};
    padding-right: ${(props) => (props.right ? `${props.right}px` : 0)};
    ${(props) => (props.left ? `transform: translateX(${props.left}px);` : undefined)}
    position: absolute;
    display: inline-block;
    top: ${(props) => (props.top ? `${props.top}px` : 0)};
    z-index: 1;
    font-family: ${Theme.font.body};

    &:hover,
    &.opened-children {
        width: auto;
        z-index: 5;
    }
`;
let dropTarget;
let openShiftDrop = false;
let dropResult;
let driverToOpenShift;

const TableApp = ({ weekDate, fakeRefresh }) => {
    const [state, dispatch] = useContext(Store);
    const tableRef = useRef();
    const { api } = useAuth();

    const [publishUpdate] = fakeRefresh;

    let driverShiftData = state.data.driversShifts;
    let openShiftData = state.data.openShifts;
    let backupShiftData = state.data.backupDrivers;
    if (state.hasFilter) {
        driverShiftData = state.filter;
        openShiftData = state.openFiltered;
        backupShiftData = state.backupFiltered;
    }

    const [selectedStation] = useSelectedStation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const schedulerApi = useSchedulerApi();
    const station = JSON.parse(localStorage.getItem("station"));
    const [menuVisible, setMenuVisible] = useState(null);
    const cancelTokenRef = useRef(null);
    const CancelToken = axios.CancelToken;

    useEffect(() => {
        const handleClickOutside = (e) => {
            const tableContainer = document.querySelector('.table-container');
            if (!isEditFormOpen && tableContainer && !tableContainer.contains(e.target) && menuVisible) {
                setSelectedRows([]);
                dispatch({
                    type: "EMPTY_BOX_SELECT",
                    payload: false,
                });
            }
        };
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [isEditFormOpen]);

    const fetchData = async (filterDate) => {
        if (cancelTokenRef.current) {
           cancelTokenRef.current("Canceling the previous request");
        }
        filterDate = filterDate + " 03:00:00";
        let week = moment(filterDate).isoWeek();
        if (moment(filterDate).day() === 0) {
            week = moment(filterDate).add(1, "days").isoWeek();
        }
        let year = parseInt(moment(filterDate).startOf("week").format("YYYY"));
        if (moment(filterDate).startOf("week").format('YYYY') != moment(filterDate).endOf("week").format('YYYY') && week == 1 && year == moment(filterDate).startOf("week").format('YYYY')) {
            year = parseInt(moment(filterDate).endOf("week").format('YYYY'));
        }
        const params = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "getDriverShiftByDateForDayView",
                            get: "routes",
                            excludes: [
                                "skill",
                                "vehicle",
                                "device",
                                "driver",
                                "station",
                                "stations",
                                "shiftType",
                                "schedule",
                                "vehicleDriverRecord",
                                "status",
                                "tempDriverRoutes",
                                "kickoffLog",
                                "returnToStation",
                                "oldDriver",
                                "oldDriverRoute",
                                "driverRoutes",
                                "oldTempDriverRoutes",
                                "company",
                                "user",
                                "events",
                                "updatedBy",
                            ],
                            criteria: {
                                date: filterDate,
                                timezoneOffset: getOffset(filterDate).offset,
                                week: week,
                                year: year,
                                station: selectedStation,
                                companyId: getCompanyId()
                            },
                        },
                    },
                },
            },
        };

        try {
            const response = await api.post("/api/lazy/manage/data?when=" + state.lastModified, params, {
                cache: {
                    exclude: {
                        query: false,
                    },
                },
                cancelToken: new CancelToken((c) => {
                    cancelTokenRef.current = c;
                }),
            });
            const result = {
                hours: response.data.data.routes.hours,
                driversShifts: response.data.data.routes.drivers,
                openShifts: response.data.data.routes.openShifts,
                backupDrivers: response.data.data.routes.backupDrivers,
            };

            const filters = {
                stations: response.data.data.routes.filter.stations,
                schedules: response.data.data.routes.filter.schedules,
                shiftTypes: response.data.data.routes.filter.shiftTypes,
                drivers: response.data.data.routes.filter.drivers,
                skills: response.data.data.routes.filter.skills
            };

            if (result.hours.length <= 0) {
                const hours = [
                    { hour: 5, text: "5am" },
                    { hour: 6, text: "6am" },
                    { hour: 7, text: "7am" },
                    { hour: 8, text: "8am" },
                    { hour: 9, text: "9am" },
                    { hour: 10, text: "10am" },
                    { hour: 11, text: "11am" },
                    { hour: 12, text: "12pm" },
                    { hour: 13, text: "1pm" },
                    { hour: 14, text: "2pm" },
                    { hour: 15, text: "3pm" },
                    { hour: 16, text: "4pm" },
                    { hour: 17, text: "5pm" },
                    { hour: 18, text: "6pm" },
                    { hour: 19, text: "7pm" },
                    { hour: 20, text: "8pm" },
                ];
                result.hours = hours;
            }

            dispatch({
                type: "ADD",
                payload: { data: result, filter: filters, changes: response.data.data.routes.changes },
            });

            let week = moment(filterDate).isoWeek();
            if (moment(filterDate).day() === 0) {
                week = moment(filterDate).add(1, "days").isoWeek();
            }
            let year = parseInt(moment(filterDate).startOf("week").format("YYYY"));
            if (moment(filterDate).startOf("week").format('YYYY') != moment(filterDate).endOf("week").format('YYYY') && week == 1 && year == moment(filterDate).startOf("week").format('YYYY')) {
                year = parseInt(moment(filterDate).endOf("week").format('YYYY'));
            }

            let data = await API.fetchPendingChanges(
                {
                    station: state.selectedStation,
                    wknumber: week,
                    unpublishDataDate: moment(filterDate).format("YYYY-MM-DD"),
                    year: year,
                    type: "day",
                },
                api
            );

            let chnagesId = [];
            data.forEach(function (change) {
                chnagesId.push(change.id);
            });
            dispatch({
                type: "SET_PUBLISH_COUNT",
                ids: chnagesId,
                station: state.selectedStation,
                api: true,
            });

            if (tableRef.current) {
                tableRef.current.forceUpdateGrid();
            }

            const apiPromise = import("../../Incidents/api");

            apiPromise.then((module) => {
                module.default.fetchFormOptions({ station: "" }).then(
                    (response) => {
                        dispatch({
                            type: "SET_INCIDENT_TYPES",
                            payload: response.data.data.incidentTypes,
                        });
                    },
                    (error) => false
                );
            });
        } catch (error) {
            console.error({ error });
            console.error("[ERROR]", error.message ? error.message : "");
        }
    };

    const getAuthoriseCompleteIncident = async () => {
        let completeIncindetParam = {
            "actions": {
                "response": {
                    "Station": {
                        "custom": {
                            "functionName": "getUserAccessAuthoriseCompleteIncident",
                            "get": "result",
                            "excludes": [
                            ],
                            "criteria": {
                                "stationId":selectedStation,
                                "userId":getUserId()
                            }
                        }
                    }
                }
            }
        };
        let response = await api.post("/api/lazy/manage/data", completeIncindetParam);
        let result = response.data.data.result;
        if (result) {
            await dispatch({
                type: "SET_USER_ACCESS_AUTHORISE_INCIDENT",
                payload: result,
            });
        }
    }

    useEffect(() => {
        const es = new ES("scheduler");

        const parseToLocalDate = (shift) => {
            const timezone = new Date().getTimezoneOffset() / -60;
            if (typeof shift.shiftDate != "undefined") {
                timezone = new Date(shift.shiftDate).getTimezoneOffset() / -60;
            } else if (typeof shift.dateCreated != "undefined") {
                timezone = new Date(shift.dateCreated).getTimezoneOffset() / -60;
            }
            shift.startTime += timezone;
            shift.endTime += timezone;
            shift.hour =
                moment(shift.startTime, "HH.mm").format("hh:mma") +
                "-" +
                moment(shift.endTime, "HH.mm").format("hh:mma");
            return shift;
        };

        const solveType = (driverId, isBackup) => {
            if (driverId === "0.0.0") {
                return TYPES.open;
            } else if (isBackup) {
                return TYPES.backup;
            } else {
                return TYPES.driver;
            }
        };

        const dispatcherRules = (data) => {
            let { reference, from, to, item } = data;
            item = parseToLocalDate(item);

            if (to) {
                to.type = solveType(to.driverId, item.isBackup);
                to.startTime = item.startTime;
            }
            if (from) {
                from.type = solveType(from.driverId, item.isBackup);
                from.startTime = item.startTime;
            }

            if (reference.day != weekDate) {
                return true;
            }

            switch (reference.type) {
                case "SHIFT_ADDED":
                    return {
                        type: "MOVE",
                        payload: {
                            to: to,
                            from: { type: "none" },
                            shift: item,
                        },
                    };
                    break;
                case "SHIFT_MOVED":
                    return {
                        type: "MOVE",
                        payload: {
                            to: to,
                            from: from,
                            shift: item,
                        },
                    };
                    break;
                case "SHIFT_DELETED":
                    return {
                        type: "MOVE",
                        payload: {
                            to: to,
                            from: from,
                            shift: item,
                        },
                    };
                    break;
                case "SHIFT_EDITED":
                    return {
                        type: "MOVE",
                        payload: {
                            to: to,
                            from: from,
                            shift: item,
                        },
                    };
                    break;
                case "SHIFT_REMOVED":
                    return {
                        type: "MOVE",
                        payload: {
                            to: { type: "none" },
                            from: from,
                            shift: item,
                        },
                    };
                    break;
                default:
                    return false;
            }
        };

        es.onmessage = (data) => {
            data = data.data;

            const dispatchData = dispatcherRules(data);

            if (dispatchData) {
                dispatch(dispatchData);
            }
        };
        
        return () => {
            es.close();
        };
    }, []);

    const [expand, setExpand] = useState({ visible: false, id: "" });

    useEffect(() => {
        //if(weekDate === moment().format('YYYY-MM-DD')) return;
        dispatch({ type: "LOADING" });
        fetchData(weekDate);
    }, [weekDate, selectedStation]);

    useEffect(() => {
        getAuthoriseCompleteIncident();
    }, [selectedStation]);

    useEffect(() => {
        if (publishUpdate > 0) {
            fetchData(weekDate);
        }
        return () => {
            if (cancelTokenRef.current) {
                cancelTokenRef.current("canceling request");
            }
        };
    }, [publishUpdate]);

    if (state.isLoading) return <Loading style={{ width: 40, margin: "120px auto 0" }} />;

    let shiftInfo = calculateShiftSizes(openShiftData, false, state.openShifts, sizes);
    let backupInfo = calculateShiftSizes(backupShiftData, true, state.driverShifts, sizes);

    if (!state.driverShifts && backupInfo.shifts.length > 0) {
        const hourInfo = backupInfo.shifts.reduce(
            (newHourInfo, shift) => {
                if (shift.startTime < newHourInfo.lowestHour) {
                    newHourInfo.lowestHour = shift.startTime;
                }
                if (shift.endTime > newHourInfo.highestHour) {
                    newHourInfo.highestHour = shift.endTime;
                }
                return newHourInfo;
            },
            { lowestHour: 25, highestHour: 0 }
        );
        backupInfo.groupTime = hourInfo;
    }

    const noteEdit = async (driverId, note, title) => {
        return dialogPromise((success, cancel) => (
            <NoteEditDailog success={success} cancel={cancel} dispatch={dispatch} title={title} note={note} driver={driverId} />
        ));
    };

    const handleMultipleShiftEdit = async () => {
        const filteredShifts = selectedRows.filter(shift => shift.isTrain || shift.isDuty);
        const filteredIsTrain = filteredShifts.find(shift => shift?.isTrain);
        const filteredIsDuty = filteredShifts.find(shift => shift?.isDuty);
        const response = await schedulerApi.getShiftOptions({
            shift: {
                ...selectedRows[0], isTrain: filteredIsTrain?.isTrain, isDuty: filteredIsDuty?.isDuty,
                isEditTrainDutyShift: filteredIsDuty?.isEditTrainDutyShift
            },
        });
        let invoiceTypes = response.data.data.invoiceTypes;
        let ShiftTypeOption = response.data.data.shift;
        ShiftTypeOption = [{ value: "mismatch", station: station, id: "mismatch", name: "(multiple values selected)" }, ...ShiftTypeOption];
        invoiceTypes = [{ value: "mismatch", station: station, id: "mismatch", name: "(multiple values selected)" }, ...invoiceTypes];
        const rowData = selectedRows[0];
        const matchingShiftType = selectedRows.every((row, index, array) => row.typeId === array[0].typeId);
        const matchingInvoiceType = selectedRows.every((row, index, array) => (row.invoiceTypeId === array[0].invoiceTypeId));
        setIsEditFormOpen(true);
        await showFlexibleShiftBlockForm({
            options: {
                shiftTypes: ShiftTypeOption,
                shiftInvoiceTypes: invoiceTypes,
                loaded: true,
                diffTime: matchingShiftType ? false : true,
                diffshiftInvoiceType: matchingInvoiceType ? false : true
            },
            timeZoneShortName: rowData ? rowData?.timezone : shiftInfo.shifts[0].timezone,
            ...(rowData ? {} : { driver: { id: "0.0.0", stationId: selectedStation } }),
            shift: {
                ...(rowData ?? {}),
                routeId: rowData ? rowData.id : shiftInfo.shifts[0].id,
                isDuty: rowData ? rowData.category === 6 : shiftInfo.shifts[0].category === 6,
                typeId: matchingShiftType ? parseFloat(selectedRows[0].typeId) : 'mismatch',
                startTime: matchingShiftType ? parseFloat(rowData ? rowData?.startTime : shiftInfo.shifts[0].startTime) : 'mismatch',
                endTime: matchingShiftType ? parseFloat(rowData ? rowData?.endTime : shiftInfo.shifts[0].endTime) : 'mismatch',
                utcStartTime: matchingShiftType ? parseFloat(rowData ? rowData?.utcStartTime : shiftInfo.shifts[0].utcStartTime) : 'mismatch',
                utcEndTime: matchingShiftType ? parseFloat(rowData ? rowData?.utcEndTime : shiftInfo.shifts[0].utcEndTime) : 'mismatch',
            },
        }).then(async (data) => {
            if (!data) return;
            try {
                const responseDriverSkill = await schedulerApi.checkMultipleDriverSkills(selectedRows, data);
                if (responseDriverSkill.data.data.result.some(res => !res.result)) {
                    const confirmation = await confirm(
                        `WARNING: Few drivers does not have the required skill to be assigned this shift type. Are you sure you want to continue?`
                    );
                    if (!confirmation) {
                        return true;
                    }
                }
                const response = await schedulerApi.multiEditShift(selectedRows, data, [], '');
                const newShift = response.data.data.tempDriverRoute;
                for (var i = 0; i < selectedRows.length; i++) {
                    if (rowData) {
                        const from = { driverId: selectedRows[i].driverId, startTime: selectedRows[i].startTime, type: TYPES.driver };
                        dispatch({
                            type: "MOVE",
                            payload: {
                                to: { type: TYPES.driver, driverId: newShift[i].driverId, method: "move" },
                                from: from,
                                shift: newShift[i],
                                hasAlert: true,
                            },
                        });
                    } else {
                        const from = { driverId: selectedRows[i].driverId, startTime: selectedRows[i].startTime, type: TYPES.open };
                        dispatch({
                            type: "MOVE",
                            payload: {
                                to: { type: "none" },
                                from: from,
                                shift: newShift[i],
                            },
                        });
                        const fromShift = { driverId: selectedRows[i].id, startTime: selectedRows[i].startTime, type: TYPES.driver };
                        dispatch({
                            type: "MOVE",
                            payload: {
                                to: { type: TYPES.open },
                                from: fromShift,
                                shift: newShift[i],
                            },
                        });
                    }
                }
            } catch (error) {
                console.error('Error: multiCheckDriverAndShiftSkillMatch', error);
            }
        }).catch((error) => {
            console.error("Error in showFlexibleShiftBlockForm:", error);
        }).finally(() => {
            setSelectedRows([]);
            dispatch({
                type: "EMPTY_BOX_SELECT",
                payload: false,
            });
            setIsEditFormOpen(false);
        });
    }

    const handleShiftKeyEventDay = async (e) => {
        if (e.key === 'Shift' && selectedRows?.length > 0) {
            if (state.isEmptyBoxSelected) {
                const response = await openCreateMultipleShiftPopup(state, selectedRows, api, schedulerApi, true);
                dispatch({
                    type: "EMPTY_BOX_SELECT",
                    payload: false,
                });
                if (response) {
                    const newShift = response.data.data.tempDriverRoute;
                    for (var i = 0; i < selectedRows.length; i++) {
                        const from = { driverId: selectedRows[i].driverId, startTime: selectedRows[i].startTime, type: TYPES.driver };
                        dispatch({
                            type: "MOVE",
                            payload: {
                                to: { type: TYPES.driver, driverId: newShift[i].driverId, method: "move" },
                                from: from,
                                shift: newShift[i],
                                hasAlert: true,
                            },
                        });
                    }

                    toast({
                        type: "success",
                        title: "Shift Created",
                        content: "You must publish your changes.",
                    });

                    tableRef.current.recomputeRowHeights();
                }
                setSelectedRows([]);
                return;
            }
            addEditDeletePopup().then(data => {
                if (data === 'add-edit') {
                    handleMultipleShiftEdit()
                } else if (data === 'delete') {
                    handleDelete({}, true)
                } else {
                    setSelectedRows([])
                    dispatch({
                        type: "EMPTY_BOX_SELECT",
                        payload: false,
                    });
                }
            })
        }
    }

    const onRemoveMultiRoute = (data) => {
        const from = { driverId: data.driverId, startTime: data.startTime, type: TYPES.driver };
        dispatch({
            type: "MOVE",
            payload: {
                to: { type: "delete" },
                from: from,
                shift: data,
            },
        });
        dispatch({ type: "REMOVE_CHANGES", id: data.id, station: data.stationId });
        const fromMove = { driverId: data.driverId, startTime: data.startTime, type: TYPES.open };
        dispatch({
            type: "MOVE",
            payload: {
                to: { type: "none" },
                from: fromMove,
                shift: data,
            },
        });
        dispatch({ type: "REMOVE_CHANGES", id: data.id, station: data.stationId });
    }

    const handleDelete = async (rowData = {}, isMultiple) => {
        const driverRouteId = [];
        selectedRows.map((e) => {
            driverRouteId.push(e.routeId)
        })
        setIsEditFormOpen(true);
        const conf = await confirm({
            icon: true,
            text: isMultiple ?
                "WARNING: You are about delete shifts for multiple drivers! <br /> This will remove the selected driver's shifts from today's record." :
                "This will treat this Shift as a scheduling error and remove only the driver's shift from today's record.",
            notificationText: "Notify driver of the shift's removal via SMS"
        });
        if (!conf) {
            setSelectedRows([]);
            setIsEditFormOpen(false);
            dispatch({
                type: "EMPTY_BOX_SELECT",
                payload: false,
            });
            return;
        }
        const { sendNotification: notifyDriver } = conf;
        await schedulerApi.dropLoadoutShift({
            driverId: selectedRows.length > 0 ? driverRouteId : rowData.routeId,
            incidentId: null,
            notifyDriver: notifyDriver,
            isMultiDelete: true,
        });
        if (selectedRows.length > 0) {
            for (var i = 0; i < selectedRows.length; i++) {
                await onRemoveMultiRoute(selectedRows[i])
            }
        } else {
            await onRemoveMultiRoute(rowData)
        }
        setSelectedRows([]);
        dispatch({
            type: "EMPTY_BOX_SELECT",
            payload: false,
        });
    }

    const moveToOpenRoute = async (data) => {
        const from = { driverId: data.driverId, startTime: data.startTime, type: TYPES.driver };
        dispatch({
            type: "MOVE",
            payload: {
                to: { type: "delete" },
                from: from,
                shift: data,
            },
        });
        dispatch({ type: "REMOVE_CHANGES", id: data.id, station: data.stationId });
        const fromOpen = { driverId: data.driverId, startTime: data.startTime, type: TYPES.driver };
        data.isOpenShift = 1;
        data.driverId = "0.0.0";
        data.driver = [];
        data.isNew = false;
        dispatch({
            type: "MOVE",
            payload: {
                to: { type: TYPES.open },
                from: fromOpen,
                shift: data,
                hasAlert: false,
            },
        });
    }

    const handleOpenDelete = async (data) => {
        const driverRouteId = [];
        selectedRows && selectedRows.map((e) => {
            driverRouteId.push(e.routeId)
        })
        const openRouteConfi = await confirm({
            icon: true,
            text: "This will treat this Shift as a scheduling error and remove it from today's record but will place the Route Code into Open Routes.",
        });
        if (!openRouteConfi) return;
        if (data.isNew) {
            await schedulerApi.createOpenRouteTemp({
                driverId: selectedRows.length > 0 ? driverRouteId : data.routeId,
                incidentId: null,
                isMultiDelete: selectedRows.length > 0 ? true : false
            });
        } else {
            await schedulerApi.createOpenRoute({
                driverId: selectedRows.length > 0 ? driverRouteId : data.routeId,
                incidentId: null,
                isMultiDelete: selectedRows.length > 0 ? true : false
            });
        }

        if (selectedRows.length > 0) {
            for (var i = 0; i < selectedRows.length; i++) {
                await moveToOpenRoute(selectedRows[i])
            }
        } else {
            await moveToOpenRoute(data)
        }
        setSelectedRows([]);
        dispatch({
            type: "EMPTY_BOX_SELECT",
            payload: false,
        });
        return toast({
            type: "success",
            title: "Shift and Route successfully moved in open route.",
        });
    }

    return (
        <AutoSizerContainer>
            <AutoSizer>
                {({ height, width }) => {
                    if (width < 1) return false;
                    let hourWidth = (width - DriverColumnWidth) / state.data.hours.length;

                    return (
                        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                            <div className="table-container" style={{ userSelect: 'none', outline: 'none' }} tabIndex="0" onKeyUp={handleShiftKeyEventDay}>
                                <Table
                                    width={width}
                                    height={height}
                                    ref={tableRef}
                                    headerHeight={sizes.header + shiftInfo.size + backupInfo.size}
                                    rowHeight={sizes.defaultClose}
                                    rowCount={driverShiftData.length}
                                    rowGetter={({ index }) => driverShiftData[index]}
                                    headerStyle={HeaderStyle}
                                    className="dayView"
                                    noRowsRenderer={() => <Empty />}
                                >
                                    <Column
                                        label="Driver"
                                        dataKey="driver"
                                        width={DriverColumnWidth}
                                        headerRenderer={({ dataKey, label }) => (
                                            <HeaderRender
                                                dataKey={dataKey}
                                                label={label}
                                                width={DriverColumnWidth}
                                                shiftInfo={shiftInfo}
                                                backupInfo={backupInfo}
                                                backupDriver={backupShiftData}
                                                windowHeight={height}
                                                hourWidth={hourWidth}
                                                handleOpenDelete={handleOpenDelete}
                                            />
                                        )}
                                        cellRenderer={({ rowData }) => (
                                            <DriverRender
                                                id={rowData.id}
                                                name={rowData.name}
                                                workHours={rowData.workHours}
                                                estimatedWorkHours={rowData.expectedWorkHours}
                                                shiftCount={rowData.driverShiftsCount}
                                                notes={rowData.notes}
                                                profile={rowData.profile}
                                                backupDriver={backupShiftData}
                                                onClick={() => setExpand({ visible: true, id: rowData.id })}
                                                onNoteAdd={() => { noteEdit(rowData.id, rowData.notes, 'Note Add') }}
                                                onNoteEdit={() => { noteEdit(rowData.id, rowData.notes, 'Note Edit') }}
                                            />
                                        )}
                                    />

                                    {state.data.hours.map((item, key) => (
                                        <Column
                                            label={item.text}
                                            dataKey={item.hour}
                                            width={hourWidth}
                                            key={key}
                                            index={key}
                                            headerRenderer={({ dataKey, label }) => (
                                                <HourCell
                                                    hourWidth={hourWidth}
                                                    dataKey={dataKey}
                                                    label={label}
                                                    shiftInfo={shiftInfo}
                                                    backupInfo={backupInfo}
                                                    backupDriver={backupShiftData}
                                                    firstKey={state.data.hours[0].hour}
                                                    selectedRows={selectedRows}
                                                    setSelectedRows={setSelectedRows}
                                                    isHovered={isHovered}
                                                    setIsHovered={setIsHovered}
                                                    setIsEditFormOpen={setIsEditFormOpen}
                                                    handleDelete={handleDelete}
                                                    menuVisible={menuVisible}
                                                    setMenuVisible={setMenuVisible}
                                                    handleOpenDelete={handleOpenDelete}
                                                />
                                            )}
                                            cellRenderer={({ rowData, columnIndex, dataKey }) => (
                                                <ShiftRender
                                                    time={item}
                                                    data={rowData}
                                                    columnIndex={columnIndex}
                                                    dataKey={dataKey}
                                                    hourWidth={hourWidth}
                                                    selectedRows={selectedRows}
                                                    setSelectedRows={setSelectedRows}
                                                    fakeRefresh={fakeRefresh}
                                                    tableRef={tableRef}
                                                    handleDelete={handleDelete}
                                                    menuVisible={menuVisible}
                                                    setMenuVisible={setMenuVisible}
                                                    handleOpenDelete={handleOpenDelete}
                                                />
                                            )}
                                        />
                                    ))}
                                </Table>

                                {expand.visible && (
                                    <Details
                                        profileId={expand.id}
                                        detailVisible={expand.visible}
                                        toggleDetails={() => {
                                            setExpand({ ...expand, visible: false });
                                        }}
                                        modalHandler={() => { }}
                                        isEdit="false"
                                    />
                                )}
                            </div>
                        </DndProvider>
                    );
                }}
            </AutoSizer>
        </AutoSizerContainer>
    );
    //  <Footer innerRef={tableRef} />
};

const RealTimeLine = styled.div`
    position: absolute;
    width: 1px;
    height: 100%;
    background: #f7931e;
    top: 37px;
    z-index: 20;
    left: ${(props) => (props.left ? DriverColumnWidth + props.left + "px" : DriverColumnWidth + "px")};
    pointer-events: none;
`;
const RealTimeBox = styled.div`
    width: ${(props) => props.width}px;
    height: 38px;
    border: 1px solid #f7931e;
    display: block;
    position: absolute;
    top: -1px;
    left: ${(props) => (props.left ? DriverColumnWidth + props.left + "px" : DriverColumnWidth + "px")};
    pointer-events: none;
`;
const RealTimeRef = ({ hourWidth, height }) => {
    const [distance, setDistance] = useState({ line: 0, box: 0 });
    const [state, dispatch] = useContext(Store);

    const firstHour = state.data.hours[0].hour;
    const lastHour = state.data.hours[state.data.hours.length - 1].hour;

    const solveDistance = useCallback(() => {
        const now = moment();
        const hour = now.format("HH");
        const minute = now.format("mm");
        const hourIndex = state.data.hours.findIndex((el) => el.hour == hour);
        const perSecond = hourWidth / 60;
        let box = hourIndex * hourWidth;
        let result = box + perSecond * minute;
        return { line: result, box: box - 1 };
    }, []);

    useEffect(() => {
        const result = solveDistance();
        setDistance(result);
        const timer = setInterval(() => {
            const now = moment();
            if (now.format("HH") >= firstHour && now.format("HH") <= lastHour) {
                const result = solveDistance();
                setDistance({ line: result.line, box: result.box });
            } else {
                clearInterval(timer);
            }
        }, 60000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    if (distance.line < 0) {
        return false;
    }

    return (
        <>
            <RealTimeBox left={distance.box} width={hourWidth + 1} />
            <RealTimeLine left={distance.line} style={{ height: height }} />
        </>
    );
};

const HeaderRender = ({ dataKey, width, label, shiftInfo, backupDriver, windowHeight, hourWidth }) => {
    const options = [
        { fields: ["name", "workHours"], label: "First Name" },
        { fields: ["lastName", "workHours"], label: "Last Name" },
        { fields: ["workHours"], label: "Hours Worked" },
        { fields: ["routeCode"], label: "Route Code" },
        { fields: ["shiftType"], label: "Shift Type" },
        { fields: ["scheduleName", "firstName"], label: "Schedule Name" },
    ];

    const [date] = useLocalStorage("filterWeekDate");
    const isToday = moment(date).diff(moment(), "days") === 0;

    return (
        <HeaderColumn>
            {isToday && <RealTimeRef hourWidth={hourWidth} height={windowHeight} />}
            <HeaderColumnComponent
                label={label}
                Wrapper={HeaderColumnOptions}
                colWidth={width}
                popUpDirection="right"
                options={options}
                dataKey={dataKey}
            />
            <OpenShifts
                height={shiftInfo.size} />
            <BackupDriver backupDriver={backupDriver} />
        </HeaderColumn>
    );
};

const OpenShifts = ({ height }) => {
    const [state, dispatch] = useContext(Store);
    return (
        <div
            style={{
                padding: 8,
                display: "flex",
                height: height,
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #ccc",
            }}
        >
            <div>
                <ThirdTitle style={{ fontWeight: "400", color: "#333333" }}>Open Shifts</ThirdTitle>
                <Tooltip verticalPosition={"center"} horizontalPosition={"right"} content={"Confirmed Shifts"}>
                    <span
                        style={{
                            fontSize: 12,
                            color: "#707070",
                            display: "inline-flex",
                            alignItems: "center",
                        }}
                    >
                        <Icon.CheckSquare size="12px" color="#707070" style={{ marginRight: "4px" }} />
                        {state.openFiltered.length}
                    </span>
                </Tooltip>
                <Spacer left={3} inline>
                    <Tooltip verticalPosition={"center"} horizontalPosition={"right"} content={"Shift Requests"}>
                        <span
                            style={{
                                fontSize: 12,
                                color: "#707070",
                                display: "inline-flex",
                                alignItems: "center",
                            }}
                        >
                            <Icon.Warning size="12px" color="#707070" style={{ marginRight: "4px" }} />0
                        </span>
                    </Tooltip>
                </Spacer>
            </div>
            {state.openShifts ? (
                <Icon.ArrowUp
                    size="16px"
                    color="#7C98B6"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        dispatch({ type: "EXPAND_OPEN_SHIFTS" });
                        /*if(state.refTable.current){
                                        state.refTable.current.recomputeRowHeights();
                                    }*/
                    }}
                />
            ) : (
                <Icon.ArrowDown
                    size="16px"
                    color="#7C98B6"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        dispatch({ type: "EXPAND_OPEN_SHIFTS" });
                        /*if(state.refTable.current){
                                        state.refTable.current.recomputeRowHeights();
                                    }*/
                    }}
                />
            )}
        </div>
    );
};

const BackupDriver = ({ backupDriver }) => {
    const [state, dispatch] = useContext(Store);
    return (
        <div>
            <div
                style={{
                    padding: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div>
                    <ThirdTitle style={{ fontWeight: "400", color: "#333333" }}>Backup Driver</ThirdTitle>
                    <Tooltip verticalPosition={"center"} horizontalPosition={"right"} content={"Confirmed Shifts"}>
                        <span
                            style={{
                                fontSize: 12,
                                color: "#707070",
                                display: "inline-flex",
                                alignItems: "center",
                            }}
                        >
                            <Icon.CheckSquare size="12px" color="#707070" style={{ marginRight: "4px" }} />
                            {state.backupFiltered.length}
                        </span>
                    </Tooltip>
                    <Spacer left={3} inline>
                        <Tooltip verticalPosition={"center"} horizontalPosition={"right"} content={"Shift Requests"}>
                            <span
                                style={{
                                    fontSize: 12,
                                    color: "#707070",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                            >
                                <Icon.Warning size="12px" color="#707070" style={{ marginRight: "4px" }} />0
                            </span>
                        </Tooltip>
                    </Spacer>
                </div>
                {state.driverShifts ? (
                    <Icon.ArrowUp
                        size="16px"
                        color="#7C98B6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            dispatch({ type: "EXPAND_DRIVER_SHIFTS" });
                            /*if(state.refTable.current){
                                              state.refTable.current.recomputeRowHeights();
                                          }*/
                        }}
                    />
                ) : (
                    <Icon.ArrowDown
                        size="16px"
                        color="#7C98B6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            dispatch({ type: "EXPAND_DRIVER_SHIFTS" });
                            /*if(state.refTable.current){
                                              state.refTable.current.recomputeRowHeights();
                                          }*/
                        }}
                    />
                )}
            </div>
            {state.driverShifts &&
                backupDriver.map((item, key) => {
                    return (
                        <DriverRender
                            key={key}
                            name={item.name}
                            workHours={item.workHours}
                            estimatedWorkHours={item.expectedWorkHours}
                            shiftCount={item.driverShiftsCount}
                            notes={item.notes}
                            profile={item.profile}
                            onNoteAdd={() => {/*noteEdit(rowData.driverId,rowData.notes,'Note Add')*/ }}
                            onNoteEdit={() => {/*noteEdit(rowData.driverId,rowData.notes,'Note Edit')*/ }}
                        />
                    );
                })}
        </div>
    );
};

const HourCellStyle = styled.div`
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 14px;
    font-family: circe-rounded;
    color: #707070;
    padding: 8px;
    height: 37px;
`;

const DraggableStyle = styled.div`
    width: 100%;
    border-bottom: ${({ hasBorder }) => (hasBorder ? "1px solid #ccc" : "none")};
    height: ${({ height }) => (height ? height + "px" : "100%")};
    position: relative;

    &:hover {
        background: ${Theme.colors.primary}1f;
    }
`;

const HourCell = ({ dataKey, label, hourWidth, shiftInfo, backupInfo, firstKey, selectedRows, setSelectedRows,
    isHovered, setIsHovered, setIsEditFormOpen, handleDelete, menuVisible, setMenuVisible, handleOpenDelete }) => {
    const [state, dispatch] = useContext(Store);
    const { api } = useAuth();
    const [schedulerDate] = useLocalStorage("schedulerDate", moment());
    const [selectedStation] = useSelectedStation();
    const schedulerApi = useSchedulerApi();
    const hrCellPopUpRef = useRef();

    let openShift;
    const openShiftFormated = getShiftFormatted(shiftInfo.shifts, "shift", dataKey, state.openShifts, firstKey);

    const actions = {
        onMoveToOpenRoute: (result) => {
            // const from = { driverId: data.id, startTime: time.hour, type: TYPES.driver }
            // dispatch({
            //     type: "MOVE",
            //     payload: {
            //         to: { type: TYPES.open },
            //         from: from,
            //         shift: shift,
            //     },
            // });
        },
        onAssignToAnotherDriver: (driver, shift) => {
            // const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            // shift.driverId = driver.id;
            // dispatch({
            //     type: "MOVE",
            //     payload: {
            //         to: { type: TYPES.driver, driverId: driver.id },
            //         from: from,
            //         shift: shift,
            //     },
            // });
        },
        onDropRoute: (shift, dropRouteStatus) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.backup };

            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: "none" },
                    from: from,
                    shift: shift,
                },
            });
        },

        onRemoveRoute(shift) {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.open };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: "none" },
                    from: from,
                    shift: shift,
                },
            });
            dispatch({ type: "REMOVE_CHANGES", id: shift.id, station: shift.stationId });
        },
        onAddRescue: (shift, rescueShift, rescuedShifts) => {
            actions.onEditShift(shift, shift);
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.backup, driverId: shift.driverId },
                    from: { type: "none" },
                    shift: rescueShift,
                },
            });
        },
        onAddTrainer(shift, newShift) {
            let driver = {};
            let traineeShift = {};
            if (newShift.isTrain) {
                shift.trainee.forEach((trainee) => {
                    driver = state.filter.find((d) => d.id == trainee.driverId);
                    if (driver) {
                        traineeShift = driver["shift_" + trainee.startTime];
                        if (traineeShift) {
                            traineeShift.priorShiftRouteId = null;
                            traineeShift.priorShiftType = "";

                            const from = {
                                driverId: traineeShift.driverId,
                                startTime: traineeShift.startTime,
                                type: TYPES.backup,
                            };
                            dispatch({
                                type: "MOVE",
                                payload: {
                                    to: { type: TYPES.backup, driverId: traineeShift.driverId },
                                    from: from,
                                    shift: shift,
                                },
                            });
                            traineeShift = {};
                            driver = {};
                        }
                    }
                });

                newShift.trainee.forEach((trainee) => {
                    driver = state.filter.find((d) => d.id == trainee.driverId);
                    if (driver) {
                        traineeShift = driver["shift_" + trainee.startTime];
                        if (traineeShift) {
                            traineeShift.priorShiftRouteId = newShift.id;
                            traineeShift.priorShiftType = "addTrainee";

                            const from = {
                                driverId: traineeShift.driverId,
                                startTime: traineeShift.startTime,
                                type: TYPES.backup,
                            };
                            dispatch({
                                type: "MOVE",
                                payload: {
                                    to: { type: TYPES.backup, driverId: traineeShift.driverId },
                                    from: from,
                                    shift: traineeShift,
                                },
                            });
                            traineeShift = {};
                            driver = {};
                        }
                    }
                });
            }

            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.backup };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.backup, driverId: newShift.driverId },
                    from: from,
                    shift: newShift,
                },
            });
        },
        onEditShift: (shift, newShift) => {
            if (shift.driverId != "0.0.0") {
                const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.backup };
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: TYPES.backup, driverId: shift.driverId },
                        from: from,
                        shift: newShift,
                    },
                });
            } else {
                const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.open };
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: "none" },
                        from: from,
                        shift: shift,
                    },
                });
                const fromShift = { driverId: shift.id, startTime: newShift.startTime, type: TYPES.driver };
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: TYPES.open },
                        from: fromShift,
                        shift: newShift,
                    },
                });
                dispatch({ type: "SET_PUBLISH_COUNT", ids: [shift.id] });
            }
        },

        onEditShiftNote: (shift, newShift) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.backup };

            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.backup, driverId: shift.driverId },
                    from: from,
                    shift: newShift,
                    isNotCount: true,
                },
            });
        },
        onRouteChange: (shift, newShift) => {
            shift.shift = shift;
            dispatch({
                type: "UPDATE_ROUTE_CODES",
                payload: {
                    shift,
                    newShift,
                },
            });
        },
        onVehicleChange({ description, callback }) {
            callback();
        },
        onDeviceChange({ description, callback }) {
            callback();
        },
        onSendHome(shift) {
            dispatch({
                type: "UPDATE_SINGLE_SHIFT",
                payload: { type: "backupDrivers", filterIndx: "backupFiltered", shift: shift },
            });
        },
        onUnSendHome(shift) {
            dispatch({
                type: "UPDATE_SINGLE_SHIFT",
                payload: { type: "backupDrivers", filterIndx: "backupFiltered", shift: shift },
            });
        },
        onAddRefuseRescue(shift) {
            shift.isRefusedRescue = true;
            dispatch({
                type: "UPDATE_SINGLE_SHIFT",
                payload: { type: "backupDrivers", filterIndx: "backupFiltered", shift: shift },
            });
        },
        onEditNormalShift: (shift) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.backup };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.backup, driverId: shift.driverId },
                    from: from,
                    shift: shift,
                },
            });
        },
    };

    if (openShiftFormated.length > 0) {
        openShift = openShiftFormated.map((shift, key) => {
            const { marginLeft, marginRight, columnWidth } = ShiftWrapper(shift.endTime, shift.startTime, hourWidth);
            let top = shift.key * sizes.shiftHeight + (shift.key + 1) * sizes.shiftTopPadding + 4;

            return (
                <ShiftBlockWrapper
                    key={key}
                    top={top || sizes.shiftTopPadding}
                    width={columnWidth}
                    left={marginLeft}
                    right={marginRight}
                >
                    <ShiftDraggableHandler
                        shift={shift}
                        from={{ type: TYPES.open, shiftId: shift.id }}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        schedulerApi={schedulerApi}
                    >
                        <ShiftBlock
                            hour={shift.hour}
                            type={shift.type}
                            color={shift.color}
                            background={shift.bg}
                            schedule={shift.sheduleName}
                            hasAlert={shift.qty > 1 ? shift.qty : shift.isNew}
                            hasIncident={shift.incident != null ? shift.incident.type : false}
                            sentToHome={shift.incident}
                            routeCode={shift.routeCode}
                            hasInvoice={shift.invoiceTypeId > 0}
                            isExempt={shift.isExempt}
                            hasCallOutOrNcns={shift.hasCallOutOrNcns}
                            isTimeOff={shift.isTimeOff}
                            hasAnySinglePunch={shift.hasAnySinglePunch}
                            category={shift.category}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            isHovered={isHovered}
                            setIsHovered={setIsHovered}
                            setIsEditFormOpen={setIsEditFormOpen}
                            shift={shift}
                            handleDelete={handleDelete}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            handleOpenDelete={handleOpenDelete}
                        >
                            {({ open, setOpen }) => {
                                return (
                                    <ShiftBlockPopup
                                        shift={shift}
                                        open={{ open, setOpen }}
                                        actions={actions}
                                        schedule={shift.sheduleName}
                                        viewType="day"
                                        isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                    />
                                );
                            }}
                        </ShiftBlock>
                    </ShiftDraggableHandler>
                </ShiftBlockWrapper>
            );
        });
    }

    let backupShifts;
    if (backupInfo.shifts) {
        let backupShiftOptions = [];
        if (state.driverShifts) {
            backupShiftOptions = getShiftFormatted(backupInfo.shifts, "backup", dataKey, state.driverShifts, firstKey);
        } else {
            let groupKey = 0;
            if (backupInfo.groupTime && backupInfo.groupTime.lowestHour) {
                const groupKeyArray = backupInfo.groupTime.lowestHour.toString().split(".");
                groupKey = parseInt(groupKeyArray[0]);
            }
            if (groupKey === dataKey) {
                backupShiftOptions = backupInfo.shifts.reduce(
                    (newShift, shift, index) => {
                        const checkShift = checkIfShiftAlreadyExist(newShift.shift, shift, "backup");

                        if (checkShift < 0) {
                            shift.qty = 1;
                            newShift.shift[newShift.key] = shift;
                            newShift.shift[newShift.key].key = newShift.key;
                            newShift.key += 1;
                        } else {
                            const oldKey = newShift.key - 1;
                            newShift.shift[oldKey].qty += 1;
                            if (shift.endTime > newShift.shift[oldKey].endTime) {
                                newShift.shift[oldKey].endTime = shift.endTime;
                            }
                        }

                        return newShift;
                    },
                    { key: 0, shift: [] }
                ).shift;
            }
        }

        backupShifts = backupShiftOptions.map((shift, key) => {
            const shiftRealEndTime = state.driverShifts ? shift.endTime : backupInfo.groupTime.highestHour;
            const shiftRealStartTime = state.driverShifts ? shift.startTime : backupInfo.groupTime.lowestHour;
            const { marginLeft, marginRight, columnWidth } = ShiftWrapper(
                shiftRealEndTime,
                shiftRealStartTime,
                hourWidth
            );

            let incidentTypes = {};
            if (state && state.incidentTypes) {
                incidentTypes = state.incidentTypes;
            }

            const top = shift.key * sizes.shiftHeight + (shift.key + 1) * sizes.shiftTopPadding;
            return (
                <ShiftBlockWrapper
                    key={key}
                    top={top || sizes.shiftTopPadding}
                    width={columnWidth}
                    left={marginLeft}
                    right={marginRight}
                >
                    <ShiftDraggableHandler
                        shift={shift}
                        from={{
                            type: TYPES.backup,
                            shiftId: shift.id,
                            driverId: shift.driverId,
                        }}
                        avoidDrag={state.driverShifts ? false : true}
                    >
                        {shift.deleted ? (
                            <div style={{ display: "block" }}>
                                <ShiftBlock
                                    hour={state.driverShifts ? shift.hour : shift.type}
                                    type={state.driverShifts ? shift.type : ""}
                                    color={shift.color}
                                    schedule={shift.sheduleName}
                                    timeline={shift.timeline}
                                    background={shift.bg}
                                    hasAlert={
                                        shift.isNew
                                            ? shift.isNew
                                            : shift.qty > 0 && !state.driverShifts
                                                ? shift.qty
                                                : false
                                    }
                                    hasIncident={shift.incident != null ? shift.incident.type : false}
                                    sentToHome={shift.isSentHome}
                                    routeCode={state.driverShifts ? shift.routeCode : []}
                                    hasCallOutOrNcns={shift.hasCallOutOrNcns}
                                    isTimeOff={shift.isTimeOff}
                                    hasAnySinglePunch={shift.hasAnySinglePunch}
                                    isDeleted={shift.deleted}
                                    category={shift.category}
                                    menuVisible={menuVisible}
                                    setMenuVisible={setMenuVisible}
                                    handleOpenDelete={handleOpenDelete}
                                >
                                    {({ open, setOpen }) => {
                                        if (!state.driverShifts) setOpen(false);

                                        return (
                                            <ShiftBlockPopup
                                                shift={shift}
                                                open={{ open, setOpen }}
                                                actions={actions}
                                                schedule={shift.sheduleName}
                                                incidents={incidentTypes}
                                                viewType="day"
                                                isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                            />
                                        );
                                    }}
                                </ShiftBlock>
                            </div>
                        ) : (
                            <ShiftBlock
                                hour={state.driverShifts ? shift.hour : shift.type}
                                type={state.driverShifts ? shift.type : ""}
                                color={shift.color}
                                schedule={shift.sheduleName}
                                background={shift.bg}
                                timeline={shift.timeline}
                                hasAlert={
                                    shift.isNew ? shift.isNew : shift.qty > 0 && !state.driverShifts ? shift.qty : false
                                }
                                hasIncident={shift.incident != null ? shift.incident.type : false}
                                sentToHome={shift.isSentHome}
                                routeCode={state.driverShifts ? shift.routeCode : []}
                                hasCallOutOrNcns={shift.hasCallOutOrNcns}
                                isTimeOff={shift.isTimeOff}
                                hasAnySinglePunch={shift.hasAnySinglePunch}
                                category={shift.category}
                                handleDelete={handleDelete}
                                menuVisible={menuVisible}
                                setMenuVisible={setMenuVisible}
                                handleOpenDelete={handleOpenDelete}
                            >
                                {({ open, setOpen }) => {
                                    if (!state.driverShifts) setOpen(false);

                                    return (
                                        <ShiftBlockPopup
                                            shift={shift}
                                            open={{ open, setOpen }}
                                            actions={actions}
                                            schedule={shift.sheduleName}
                                            incidents={incidentTypes}
                                            viewType="day"
                                            isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                        />
                                    );
                                }}
                            </ShiftBlock>
                        )}
                    </ShiftDraggableHandler>
                </ShiftBlockWrapper>
            );
        });
    }

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [TYPES.driver, TYPES.backup],
        hover: (item) => {
            //console.log('item',item)
        },
        drop: async (item) => {
            openShiftDrop = false;
            driverToOpenShift = false;
        },
        collect: (monitor) => ({
            canDrop: selectedRows?.length === 0 ? monitor.canDrop() : false,
        })
    });

    const handleAdd = async (e) => {
        let date = moment.utc(schedulerDate);
        let getSelectedDate = document.querySelector("#day-view-calendar").value;
        let selectedDate = moment(getSelectedDate).format("YYYY-MM-DD");

        if (localStorage.getItem('role') == 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE') {
            return alert("You don't have permission.");
        }

        /*if ( selectedDate < moment().format("YYYY-MM-DD"))
        return alert("You cannot add shifts to the past");*/
        let isTimeoff = false;
        let shift = {
            stationId: selectedStation,
            driverId: "0.0.0",
            driver: { id: "0.0.0" },
            shiftDate: selectedDate,
            utcStartTime: dataKey + new Date().getTimezoneOffset() / 60,
            isAdd: true,
        };

        const paramsStationObject = {
            actions: {
                response: {
                    Station: {
                        findOneBy: {
                            criteria: {
                                id: selectedStation,
                            },
                            get: "Station",
                            includes: {
                                0: "id",
                                1: "name",
                                2: "stationAddress",
                                3: "stationGeofence",
                                4: "parkingLotAddress",
                                5: "parkingLotGeofence",
                                6: "code",
                                7: "timezone",
                            },
                            excludes: [
                                "company",
                                "skill",
                                "users",
                                "parent",
                                "childrens",
                                "driverRoutes",
                                "payRate",
                                "skillRates",
                                "shifts",
                                "drivers",
                            ],
                        },
                    },
                },
            },
        };
        let responseStationObj = await api.post("/api/lazy/manage/data", paramsStationObject);
        let timeZoneShortName = null;
        if (responseStationObj.data.data.Station) {
            let currentSelectedStationObj = responseStationObj.data.data.Station;
            timeZoneShortName = currentSelectedStationObj.timezone;
        }

        showFlexibleShiftBlockForm({
            title: "Add Shift",
            api: schedulerApi,
            driver: { id: "0.0.0", stationId: selectedStation },
            selectedDate,
            shift,
            isTimeoff,
            timeZoneShortName: timeZoneShortName,
            currentPopRef: hrCellPopUpRef.current
        }).then(async (newShift) => {
            if (!newShift) return;
            schedulerApi
                .tempEditShift({
                    id: null,
                    newRecord: {
                        ...newShift,
                        typeId: newShift.shiftType,
                        driverId: "0.0.0",
                        shiftDate: selectedDate,
                        isTemp: true,
                    },
                })
                .then((response) => {
                    if (response) {
                        toast({
                            type: "success",
                            title: "Shift Created",
                            content: "You must publish your changes.",
                        });
                        dispatch({
                            type: "MOVE",
                            payload: {
                                to: { type: TYPES.open },
                                from: { type: "none" },
                                shift: response.data.data.tempDriverRoute,
                            },
                        });
                        dispatch({
                            type: "SET_PUBLISH_COUNT",
                            ids: [response.data.data.tempDriverRoute.id],
                            station: response.data.data.stationId,
                            api: false,
                        });
                    }
                });
        });
    };

    const handleSelection = () => {
        let getSelectedDate = document.querySelector("#day-view-calendar").value;
        let selectedDate = moment(getSelectedDate).format("YYYY-MM-DD");
        setSelectedRows((prevSelectedRows) => {
            console.log("dataKey>>", dataKey)
            const isSelected = prevSelectedRows.findIndex((row) => row.dataKey === dataKey && row.id === '0.0.0');
            if (isSelected >= 0) {
                const tempRows = [...prevSelectedRows]
                tempRows.splice(isSelected, 1);
                if (tempRows.length === 0) {
                    dispatch({
                        type: "EMPTY_BOX_SELECT",
                        payload: false,
                    });
                }
                return tempRows;
            } else {
                let shift = {
                    stationId: selectedStation,
                    driverId: "0.0.0",
                    driver: { id: "0.0.0" },
                    shiftDate: selectedDate,
                    utcStartTime: dataKey + new Date().getTimezoneOffset() / 60,
                    isAdd: true,
                };
                return [...prevSelectedRows, {
                    id: '0.0.0',
                    driverId: '0.0.0',
                    shift,
                    dataKey,
                    date: selectedDate
                }];
            }
        });
    }

    const handleEmptyBoxClick = async (evt) => {
        if (evt.shiftKey) {
            const isBoxNotSelectable = await isEmptyDayBoxNonSelectable({ id: '0.0.0' })
            if (isBoxNotSelectable) {
                return;
            } else {
                dispatch({
                    type: "EMPTY_BOX_SELECT",
                    payload: true,
                });
                handleSelection()
            }
        } else {
            setSelectedRows([]);
            handleAdd(evt);
        }

    }

    const isBoxEmpty = !openShift;
    const isEmptySelection = selectedRows.length > 0 && !isBoxEmpty && state.isEmptyBoxSelected;
    const isNonEmptySelection = selectedRows.length > 0 && isBoxEmpty && !state.isEmptyBoxSelected;
    const borderEmptyBoxSelected = selectedRows.findIndex(row => row.dataKey === dataKey && row.id === "0.0.0") >= 0;

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <HourCellStyle>{label}</HourCellStyle>
            <DraggableStyle
                ref={drop}
                height={shiftInfo.size}
                hasBorder={true}
                onClick={handleEmptyBoxClick}
                style={{
                    width: "100%",
                    display: "block",
                    opacity: (isEmptySelection || isNonEmptySelection) ? '0.5' : '1',
                    pointerEvents: (isEmptySelection || isNonEmptySelection) ? 'none' : '',
                    border: borderEmptyBoxSelected ? '4px solid rgba(189, 55, 164, 0.5)' : ''
                }}
            >
                <div ref={hrCellPopUpRef}>
                    {openShift}
                </div>
            </DraggableStyle>
            <div
                style={{
                    padding: "8px 0",
                    height: backupInfo.size,
                    position: "relative",
                    top: state.driverShifts ? 60 : 0,
                }}
            >
                {backupShifts}
            </div>
        </div>
    );
};

const ShiftDraggableHandler = ({ shift, from, children, avoidDrag = false, data, selectedRows, setSelectedRows, schedulerApi, tableRef }) => {
    const [state, dispatch] = useContext(Store);
    const { api } = useAuth();
    const [{ isDragging }, drag] = useDrag({
        item: { shift, type: from.type, from },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: selectedRows?.length === 0,
        end: async (item, monitor) => {
            dropResult = await monitor.getDropResult();
            const hasMultipleDriverErrorMsg = () => {
                const firstDriverId = selectedRows[0]?.driverId;
                for (let i = 1; i < selectedRows.length; i++) {
                    if (selectedRows[i].driverId !== firstDriverId) {
                        return true;
                    }
                }
            }
            if (hasMultipleDriverErrorMsg()) {
                return alert(`You cannot move/copy multiple drivers`);
            }
            openShiftDrop = true;
            await dayDndMoveCopy(data, item, dropResult, TYPES, dispatch, api, selectedRows, dropTarget, shift,
                setSelectedRows, schedulerApi, openShiftDrop, driverToOpenShift, tableRef);
        }
    });

    if (avoidDrag) return children;

    let originalChildren = children;

    if (isDragging) {
        if (originalChildren.props.hasAlert && originalChildren.props.hasAlert > 1) {
            originalChildren = React.cloneElement(children, {
                hasAlert: originalChildren.props.hasAlert - 1,
            });
        }
    }

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? "0.5" : "1",
                width: "100%",
                height: "100%",
                position: "relative",
                zIndex: 5,
            }}
        >
            {originalChildren}
        </div>
    );
};

const DriverRender = ({ name, workHours, estimatedWorkHours, notes, profile, shiftCount, onClick,
    onNoteAdd, onNoteEdit }) => {
    let fullName = name;
    const totalString = 13;
    let hasTooltip = false;
    if (name.length > totalString) {
        name = name.slice(0, totalString) + "...";
        hasTooltip = true;
    }

    let spanColor = false;
    if (workHours > 40 || estimatedWorkHours > 40) {
        spanColor = true;
    }

    return (
        <ProfileColumn style={{ height: "100%", padding: "8px" }}>
            <ProfileColumn tooltip={hasTooltip} style={{ width: "100%" }} onClick={onClick}>
                <div
                    className="picture"
                    data-tooltip={fullName}
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                >
                    <Driver
                        name={name}
                        jobTitle={
                            <span style={{ display: "flex", alignItems: "center", marginTop: "" }}>
                                <Icon.Clock size="12px" color="#707070" />
                                <span style={spanColor ? { color: "#D81A20" } : {}}>{workHours}</span>/{" "}
                                <span style={spanColor ? { color: "#D81A20" } : {}}>{estimatedWorkHours}</span>
                            </span>
                        }
                        size="20px"
                        img={profile ? profile : ""}
                    />
                    {shiftCount >= 5 && (
                        <span style={{ fontSize: 12, color: Theme.colors.error.text }}>{shiftCount}th</span>
                    )}
                </div>
            </ProfileColumn>
            {notes ? (
                <ProfileColumn tooltip={true} onClick={() => { onNoteEdit() }}>
                    <div className="picture" data-tooltip={notes}>
                        <svg fill={Theme.colors.info.border} height="20px" width="20px" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 498.93"><path d="M389.76 0c33.74 0 64.31 13.69 86.42 35.8C498.31 57.92 512 88.5 512 122.24c0 33.75-13.69 64.33-35.8 86.44-22.13 22.12-52.7 35.82-86.44 35.82-33.71 0-64.27-13.68-86.4-35.82-22.19-22.2-35.86-52.76-35.86-86.44 0-33.74 13.69-64.32 35.82-86.44C325.43 13.69 356.01 0 389.76 0zM127.13 93.07 45.4 179.44h81.73V93.07zM0 195.82c0-1.15.15-2.27.41-3.35.13-3.14 1.36-6.27 3.69-8.73L128.47 52.32c2.31-4.18 6.78-7.01 11.9-7.01h113.41c-4.87 8.6-8.97 17.7-12.16 27.2h-87.3v120.52c0 7.5-6.09 13.59-13.59 13.59H27.2v265.11h392.02V275.68c9.4-1.8 18.49-4.44 27.21-7.83V471.9c0 7.39-3.05 14.15-7.93 19.05-4.96 4.94-11.72 7.98-19.11 7.98H27.04c-7.43 0-14.21-3.06-19.09-7.94C3.07 486.1 0 479.32 0 471.9V195.82zm324.89 79.39c7.53 0 13.66 6.05 13.66 13.52s-6.13 13.52-13.66 13.52H121.54c-7.54 0-13.66-6.05-13.66-13.52s6.12-13.52 13.66-13.52h203.35zm0 91.23c7.53 0 13.66 6.05 13.66 13.52s-6.13 13.52-13.66 13.52H121.54c-7.54 0-13.66-6.05-13.66-13.52s6.12-13.52 13.66-13.52h203.35zm63.8-204.34-46.97 12.52 6.77-50.42 40.2 37.9zm-29.84-49.34 46.13-52.62c.77-.91 1.92-1.44 3.1-1.48.74-.01 1.48.14 2.13.5l35.78 32.53c.71.66 1.14 1.59 1.15 2.56.04 1.09-.41 2.16-1.25 2.88l-46.97 53.52-40.13-37.89h.06z" /></svg>
                    </div>
                </ProfileColumn>
            ) : (
                <ProfileColumn tooltip={false} onClick={() => { onNoteAdd() }}>
                    <Hoverable style={{ minWidth: "20px", minHeight: "20px" }} >
                        <div className="picture" data-tooltip={notes}>
                            <svg fill={Theme.colors.info.border} height="20px" width="20px" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 498.93"><path d="M389.76 0c33.74 0 64.31 13.69 86.42 35.8C498.31 57.92 512 88.5 512 122.24c0 33.75-13.69 64.33-35.8 86.44-22.13 22.12-52.7 35.82-86.44 35.82-33.71 0-64.27-13.68-86.4-35.82-22.19-22.2-35.86-52.76-35.86-86.44 0-33.74 13.69-64.32 35.82-86.44C325.43 13.69 356.01 0 389.76 0zM127.13 93.07 45.4 179.44h81.73V93.07zM0 195.82c0-1.15.15-2.27.41-3.35.13-3.14 1.36-6.27 3.69-8.73L128.47 52.32c2.31-4.18 6.78-7.01 11.9-7.01h113.41c-4.87 8.6-8.97 17.7-12.16 27.2h-87.3v120.52c0 7.5-6.09 13.59-13.59 13.59H27.2v265.11h392.02V275.68c9.4-1.8 18.49-4.44 27.21-7.83V471.9c0 7.39-3.05 14.15-7.93 19.05-4.96 4.94-11.72 7.98-19.11 7.98H27.04c-7.43 0-14.21-3.06-19.09-7.94C3.07 486.1 0 479.32 0 471.9V195.82zm324.89 79.39c7.53 0 13.66 6.05 13.66 13.52s-6.13 13.52-13.66 13.52H121.54c-7.54 0-13.66-6.05-13.66-13.52s6.12-13.52 13.66-13.52h203.35zm0 91.23c7.53 0 13.66 6.05 13.66 13.52s-6.13 13.52-13.66 13.52H121.54c-7.54 0-13.66-6.05-13.66-13.52s6.12-13.52 13.66-13.52h203.35zm53.26-302.36h23.25c4.48 0 8.15 3.75 8.15 8.14v30.27h30.24c4.49 0 8.15 3.79 8.15 8.14v23.24c0 4.4-3.75 8.15-8.15 8.15h-30.24v30.27c0 4.38-3.75 8.13-8.15 8.13h-23.25c-4.39 0-8.14-3.64-8.14-8.13v-30.27h-30.29c-4.4 0-8.15-3.66-8.15-8.15v-23.24c0-4.49 3.66-8.14 8.15-8.14h30.29V72.22c0-4.48 3.65-8.14 8.14-8.14z" /></svg>
                        </div>
                    </Hoverable>
                </ProfileColumn>
            )}
        </ProfileColumn>
    );
};

const ShiftRender = ({ data, dataKey, time, hourWidth, selectedRows, setSelectedRows, tableRef, handleDelete,
    menuVisible, setMenuVisible, handleOpenDelete, ...props }) => {
    const [state, dispatch] = useContext(Store);
    const [schedulerDate] = useLocalStorage("schedulerDate", moment());
    const { api } = useAuth();
    const schedulerApi = useSchedulerApi();
    const [selectedStation] = useSelectedStation();
    const [updateScheduler, setUpdatedScheduler] = props.fakeRefresh;
    const shiftRenderPopUpRef = useRef()
    const { handleGhostShift } = useGhostShiftApi();
    
    const actions = {
        onMoveToOpenRoute: (shift) => {
            const from = { driverId: data.id, startTime: time.hour, type: TYPES.driver };

            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.open },
                    from: from,
                    shift: shift,
                },
            });
        },
        onAssignToAnotherDriver: (driver, shift) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            shift.driverId = driver.id;
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: driver.id },
                    from: from,
                    shift: shift,
                },
            });
        },
        onDropRoute: (shift, dropRouteStatus) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: "delete" },
                    from: from,
                    shift: shift,
                },
            });
        },
        async onDropRescue(shift, rescuerDriverRoute, rescuedDriverRoutes) {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: "delete" },
                    from: from,
                    shift: shift,
                },
            });

            let rescuerDriverRouteObj = state.filter.filter((d) => d.id == rescuerDriverRoute.driverId);
            rescuerDriverRouteObj =
                rescuerDriverRouteObj[0][`shift_` + rescuerDriverRoute.startTime.toFixed(2).replace(".", ":")];
            if (rescuerDriverRouteObj) {
                rescuerDriverRouteObj.isRescuer = false;
                rescuerDriverRouteObj.dateTimeEnded = null;
                rescuerDriverRouteObj.hour = rescuerDriverRoute.hour;
                rescuerDriverRouteObj.startTime = rescuerDriverRoute.startTime;
                rescuerDriverRouteObj.endTime = rescuerDriverRoute.endTime;
                rescuerDriverRouteObj.utcStartTime = rescuerDriverRoute.utcStartTime;
                rescuerDriverRouteObj.utcEndTime = rescuerDriverRoute.utcEndTime;
                const from1 = {
                    driverId: rescuerDriverRouteObj.driverId,
                    startTime: rescuerDriverRouteObj.startTime,
                    type: TYPES.driver,
                };
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: TYPES.driver, driverId: rescuerDriverRouteObj.driverId },
                        from: from1,
                        shift: rescuerDriverRouteObj,
                        isNotCount: true,
                    },
                });
            }

            if (rescuedDriverRoutes.length > 0) {
                rescuedDriverRoutes.map((rescuedDriverRoute) => {
                    let rescuedDriverRouteObj = state.filter.filter((d) => d.id == rescuedDriverRoute.driverId);
                    rescuedDriverRouteObj = rescuedDriverRouteObj[0][`shift_` + rescuedDriverRoute.startTime];

                    if (rescuedDriverRouteObj) {
                        rescuedDriverRouteObj.isRescued = false;
                        const from1 = {
                            driverId: rescuedDriverRouteObj.driverId,
                            startTime: rescuedDriverRouteObj.startTime,
                            type: TYPES.driver,
                        };
                        dispatch({
                            type: "MOVE",
                            payload: {
                                to: { type: TYPES.driver, driverId: rescuedDriverRouteObj.driverId },
                                from: from1,
                                shift: rescuedDriverRouteObj,
                                isNotCount: true,
                            },
                        });
                    }
                });
            }
        },
        onAddRescue: (shift, rescueShift, rescuedShifts) => {
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: shift.driverId },
                    from: { type: "none" },
                    shift: shift,
                },
            });

            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: rescueShift.driverId },
                    from: { type: "none" },
                    shift: rescueShift,
                },
            });

            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            if (shift.category == 7) {
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: "delete" },
                        from: from,
                        shift: shift,
                    },
                });
            } else {
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: TYPES.driver, driverId: shift.driverId },
                        from: { type: "none" },
                        shift: rescueShift,
                    },
                });
            }
        },
        onAddShift: (shift, rescueShift, rescuedShifts) => {
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: rescueShift.driverId, method: "add" },
                    from: { type: "none" },
                    shift: rescueShift,
                },
            });

            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            if (shift.category == 7) {
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: "delete" },
                        from: from,
                        shift: shift,
                    },
                });
            } else {
                dispatch({
                    type: "MOVE",
                    payload: {
                        to: { type: TYPES.driver, driverId: shift.driverId, method: "add" },
                        from: { type: "none" },
                        shift: rescueShift,
                    },
                });
            }
        },
        onAddTrainer(shift, newShift) {
            let driver = {};
            let traineeShift = {};
            if (newShift.isTrain) {
                shift.trainee.forEach((trainee) => {
                    driver = state.filter.find((d) => d.id == trainee.driverId);
                    if (driver) {
                        traineeShift = driver["shift_" + trainee.startTime];
                        if (traineeShift) {
                            traineeShift.priorShiftRouteId = null;
                            traineeShift.priorShiftType = "";

                            const from = {
                                driverId: traineeShift.driverId,
                                startTime: traineeShift.startTime,
                                type: TYPES.driver,
                            };
                            dispatch({
                                type: "MOVE",
                                payload: {
                                    to: { type: TYPES.driver, driverId: traineeShift.driverId },
                                    from: from,
                                    shift: shift,
                                },
                            });
                            traineeShift = {};
                            driver = {};
                        }
                    }
                });

                newShift.trainee.forEach((trainee) => {
                    driver = state.filter.find((d) => d.id == trainee.driverId);
                    if (driver) {
                        traineeShift = driver["shift_" + trainee.startTime];
                        if (traineeShift) {
                            traineeShift.priorShiftRouteId = newShift.id;
                            traineeShift.priorShiftType = "addTrainee";

                            const from = {
                                driverId: traineeShift.driverId,
                                startTime: traineeShift.startTime,
                                type: TYPES.driver,
                            };
                            dispatch({
                                type: "MOVE",
                                payload: {
                                    to: { type: TYPES.driver, driverId: traineeShift.driverId },
                                    from: from,
                                    shift: traineeShift,
                                },
                            });
                            traineeShift = {};
                            driver = {};
                        }
                    }
                });
            }

            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: newShift.driverId },
                    from: from,
                    shift: newShift,
                },
            });
        },
        onEditShift: (shift, newShift) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: shift.driverId },
                    from: from,
                    shift: newShift,
                    hasAlert: true,
                },
            });
        },

        onEditShiftNote: (shift, newShift) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };

            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: shift.driverId },
                    from: from,
                    shift: newShift,
                    isNotCount: true,
                },
            });
        },
        onVehicleChange({ description, callback }) {
            callback();
        },
        onDeviceChange({ description, callback }) {
            callback();
        },
        onRouteChange: (shift, newShift) => {
            if (shift && shift.olsShiftIsOpen == true) {
                if (shift.codeCount == 1) {
                    dispatch({
                        type: "REMOVED",
                        payload: {
                            id: shift.id,
                        },
                    });
                } else {
                    let oldShift = state.openFiltered.find((d) => d.routeId == shift.routeId);
                    oldShift.routeCode = oldShift.routeCode.filter((x) => x.toString() != shift.code.toString());
                    const from = { driverId: oldShift.driverId, startTime: oldShift.startTime, type: TYPES.driver };
                    dispatch({
                        type: "MOVE",
                        payload: {
                            to: { type: TYPES.driver, driverId: oldShift.driverId },
                            from: from,
                            shift: oldShift,
                        },
                    });
                }
            } else if (shift && shift.hasRouteCodeChnage == true) {
                let driver = state.filter.find((d) => d.id == shift.driverId);
                let newDrivershift = driver[shift.startTime];
                newDrivershift.driverId = shift.driverId;
                newDrivershift.routeCode = newDrivershift.routeCode.filter(
                    (x) => x.toString() != shift.code.toString()
                );

                actions.onEditShift(newDrivershift, newDrivershift);
            } else {
                let driver = state.filter.find((d) => d.id == shift.driverId);
                let startTimeData = shift.startTime;
                if (!startTimeData.toString().split(".")[1]) {
                    startTimeData = startTimeData + ".00";
                }

                let oldDrivershiftObj = driver["shift_" + startTimeData];
                shift.shift = shift;
                if (oldDrivershiftObj) {
                    shift.shift.id = oldDrivershiftObj.id;
                }
                dispatch({
                    type: "UPDATE_ROUTE_CODES",
                    payload: {
                        shift,
                        newShift,
                    },
                });
            }
        },
        onIncidentShift(shift, incident) {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: shift.driverId },
                    from: from,
                    shift: shift,
                    isIncident: true,
                },
            });
        },
        onRemoveRoute(shift) {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: "delete" },
                    from: from,
                    shift: shift,
                },
            });
            dispatch({ type: "REMOVE_CHANGES", id: shift.id, station: shift.stationId });
        },
        moveToOpenRoute(shift, newShift) {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            shift.isOpenShift = 1;
            shift.driverId = "0.0.0";
            shift.driver = [];
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.open },
                    from: from,
                    shift: shift,
                },
            });
            actions.onRemoveRoute(shift);
        },
        onEditRescue: (shift, rescueShift) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: shift.driverId },
                    from: from,
                    shift: shift,
                    isNotCount: false,
                },
            });

            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: shift.driverId },
                    from: from,
                    shift: rescueShift,
                    isNotCount: false,
                },
            });
        },
        onSendHome(shift) {
            dispatch({
                type: "UPDATE_SINGLE_SHIFT",
                payload: { type: "driversShifts", filterIndx: "filter", shift: shift },
            });
        },
        onUnSendHome(shift) {
            dispatch({
                type: "UPDATE_SINGLE_SHIFT",
                payload: { type: "driversShifts", filterIndx: "filter", shift: shift },
            });
        },
        onAddRefuseRescue(shift) {
            shift.isRefusedRescue = true;
            dispatch({
                type: "UPDATE_SINGLE_SHIFT",
                payload: { type: "driversShifts", filterIndx: "filter", shift: shift },
            });
        },
        onEditNormalShift: (shift, newShift) => {
            const from = { driverId: shift.driverId, startTime: shift.startTime, type: TYPES.driver };
            dispatch({
                type: "MOVE",
                payload: {
                    to: { type: TYPES.driver, driverId: shift.driverId },
                    from: from,
                    shift: newShift,
                    hasAlert: false,
                },
            });
        },
        onRemoveDriverVacationOrTerminationShift: () => {
            setUpdatedScheduler(updateScheduler + 1);
        },
    };

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [TYPES.driver, TYPES.open],
        hover: (item) => {
            //console.log('item',item)
        },
        drop: async (item, monitor) => {
            openShiftDrop = false;
            dropTarget = data;
            driverToOpenShift = true;
        },
        collect: (monitor) => ({
            canDrop: selectedRows?.length === 0 ? monitor.canDrop() : false,
        })
    });

    const handleAdd = async (e) => {
        let date = moment.utc(schedulerDate);
        let timeoffornot = false;
        await Object.keys(data).map((d) => {
            if (d.indexOf("shift_") > -1) {
                console.log(data[d].category);
                if (data[d].category == 8) {
                    timeoffornot = true;
                }
            }
        });
        if (timeoffornot) {
            return false;
        }
        let getSelectedDate = document.querySelector("#day-view-calendar").value;
        let selectedDate = moment(getSelectedDate).format("YYYY-MM-DD");
        /*if ( selectedDate < moment().format("YYYY-MM-DD"))
            return alert("You cannot add shifts to the past");*/

        if (
            data.employeeStatus == false &&
            (data.inactiveDate < selectedDate ||
                data.terminationDate < selectedDate ||
                data.offboardedDate < selectedDate)
        ) {
            return alert("You cannot add shifts to to this driver");
        }

        let shift = {
            stationId: data.stationId,
            driverId: data.id,
            driver: data,
            shiftDate: selectedDate,
            utcStartTime: dataKey + new Date().getTimezoneOffset() / 60,
            isAdd: true,
        };
        // handle ghost shift
        const getResponse = await handleGhostShift({
            stationId: data.id,
            driverId: data.id,
            date: selectedDate,
        });
        if (!getResponse) return false;
        
        const params1 = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "checkSequenceDriverRoute",
                            get: "checkSequenceDriverRoute",
                            excludes: [],
                            criteria: {
                                routeId: "",
                                driverId: data.id,
                                newDate: selectedDate,
                            },
                        },
                    },
                },
            },
        };
        const response = await api.post("/api/lazy/manage/data", params1);
        if (response.data.data.checkSequenceDriverRoute.status) {
            const wc = await confirm(response.data.data.checkSequenceDriverRoute.message.web);
            if (!wc) return undefined;

            const params1 = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "createEventForConsicutiveShift",
                                get: "createEventForConsicutiveShift",
                                excludes: [],
                                criteria: {
                                    driverId: data.id,
                                    message: response.data.data.checkSequenceDriverRoute.message.bot,
                                },
                            },
                        },
                    },
                },
            };
            const response1 = await api.post("/api/lazy/manage/data", params1);
        }

        const paramsStationObject = {
            actions: {
                response: {
                    Station: {
                        findOneBy: {
                            criteria: {
                                id: data.stationId,
                            },
                            get: "Station",
                            includes: {
                                0: "id",
                                1: "name",
                                2: "stationAddress",
                                3: "stationGeofence",
                                4: "parkingLotAddress",
                                5: "parkingLotGeofence",
                                6: "code",
                                7: "timezone",
                            },
                            excludes: [
                                "company",
                                "skill",
                                "users",
                                "parent",
                                "childrens",
                                "driverRoutes",
                                "payRate",
                                "skillRates",
                                "shifts",
                                "drivers",
                            ],
                        },
                    },
                },
            },
        };
        let responseStationObj = await api.post("/api/lazy/manage/data", paramsStationObject);
        let timeZoneShortName = null;
        if (responseStationObj.data.data.Station) {
            let currentSelectedStationObj = responseStationObj.data.data.Station;
            timeZoneShortName = currentSelectedStationObj.timezone;
        }

        showFlexibleShiftBlockForm({
            title: "Add Shift",
            api: schedulerApi,
            driver: data,
            selectedDate,
            shift,
            timeZoneShortName: timeZoneShortName,
            currentPopRef: shiftRenderPopUpRef.current,
        }).then(async (newShift) => {
            if (!newShift) return;

            const driverSkillParam = {
                actions: {
                    response: {
                        Driver: {
                            custom: {
                                functionName: "checkDriverAndShiftSkillMatch",
                                get: "result",
                                excludes: [],
                                criteria: {
                                    driverId: shift.driverId,
                                    shiftId: newShift.shiftType,
                                },
                            },
                        },
                    },
                },
            };
            const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
            if (!responseDriverSkill.data.data.result.result) {
                if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                    alert({
                        text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                        btnText: "Cancel",
                    });
                    return false;
                } else {
                    const confirmation = await confirm(
                        'WARNING: "' +
                            responseDriverSkill.data.data.result.driverName +
                            '" does not have the required "' +
                            responseDriverSkill.data.data.result.shiftSkillName +
                            '" skill to be assigned this shift type. Are you sure you want to continue?'
                    );
                    if (!confirmation) {
                        return true;
                    }
                }
            }
            schedulerApi
                .tempEditShift({
                    id: null,
                    newRecord: {
                        ...newShift,
                        typeId: newShift.shiftType,
                        driverId: data.id,
                        shiftDate: selectedDate,
                        isTemp: true,
                    },
                })
                .then((response) => {
                    if (response) {
                        toast({
                            type: "success",
                            title: "Shift Created",
                            content: "You must publish your changes to notify the driver",
                        });
                        actions.onAddShift(shift, response.data.data.tempDriverRoute);
                        dispatch({
                            type: "SET_PUBLISH_COUNT",
                            ids: [response.data.data.tempDriverRoute.id],
                            station: data.stationId,
                            api: false,
                        });
                    }
                });
        });
        
    };

    const handleSelection = () => {
        let getSelectedDate = document.querySelector("#day-view-calendar").value;
        let selectedDate = moment(getSelectedDate).format("YYYY-MM-DD");
        setSelectedRows((prevSelectedRows) => {
            const isSelected = prevSelectedRows.findIndex((row) => row.dataKey === dataKey && row.id === data.id);
            if (isSelected >= 0) {
                const tempRows = [...prevSelectedRows]
                tempRows.splice(isSelected, 1);
                if (tempRows.length === 0) {
                    dispatch({
                        type: "EMPTY_BOX_SELECT",
                        payload: false,
                    });
                }
                return tempRows;
            } else {
                let shift = {
                    stationId: selectedStation,
                    driverId: data.id,
                    driver: { id: data.id },
                    shiftDate: selectedDate,
                    utcStartTime: dataKey + new Date().getTimezoneOffset() / 60,
                    isAdd: true,
                };
                return [...prevSelectedRows, {
                    ...data,
                    id: data.id,
                    driverId: data.id,
                    shift,
                    dataKey,
                    date: selectedDate
                }];
            }
        });
    }

    const handleEmptyBoxClick = async (evt) => {
        if (evt.shiftKey) {
            const isBoxNotSelectable = await isEmptyDayBoxNonSelectable(data)
            if (isBoxNotSelectable) {
                return;
            } else {
                dispatch({
                    type: "EMPTY_BOX_SELECT",
                    payload: true,
                });
                handleSelection()
            }
        } else {
            setSelectedRows([]);
            handleAdd(evt);
        }

    }

    const shiftKeys = Object.keys(data).filter((k) => {
        const patt = new RegExp(`shift_${time.hour}\\.`, "g");
        return patt.test(k);
    });

    const isBoxEmpty = shiftKeys.length === 0;
    const isEmptySelection = selectedRows.length > 0 && !isBoxEmpty && state.isEmptyBoxSelected;
    const isNonEmptySelection = selectedRows.length > 0 && isBoxEmpty && !state.isEmptyBoxSelected;
    const borderEmptyBoxSelected = selectedRows.findIndex(row => row.dataKey === dataKey && row.id === data.id) >= 0;

    //const shiftKeys = Object.keys(data).filter(k => k.match(`shift_${time.hour}`));
    if (shiftKeys.length === 0) return (<DraggableStyle
        ref={drop}
        onClick={handleEmptyBoxClick}
        style={{
            width: "100%",
            display: "block",
            opacity: (isEmptySelection || isNonEmptySelection) ? '0.5' : '1',
            pointerEvents: (isEmptySelection || isNonEmptySelection) ? 'none' : '',
            border: borderEmptyBoxSelected ? '4px solid rgba(189, 55, 164, 0.5)' : ''
        }}
    >
        <div ref={shiftRenderPopUpRef}></div>
    </DraggableStyle>);

    return shiftKeys.map((key) => {
        let shift = data[key];
        shift.driverId = data.id;
        shift.driver = data;
        const { marginLeft, marginRight, columnWidth } = ShiftWrapper(shift.endTime, shift.startTime, hourWidth);

        let incidentTypes = {};
        if (state && state.incidentTypes) {
            incidentTypes = state.incidentTypes;
        }

        const wrapperId = `wrapper-${shift.driverId}-${key}`;

        return (
            <ShiftBlockWrapper
                key={key}
                top={6}
                width={columnWidth - marginLeft}
                left={marginLeft}
                right={marginRight}
                id={wrapperId}
                style={{
                    display: "block",
                    opacity: isEmptySelection || isNonEmptySelection ? "0.5" : "1",
                    pointerEvents: isEmptySelection || isNonEmptySelection ? "none" : "",
                    border: borderEmptyBoxSelected ? "4px solid rgba(189, 55, 164, 0.5)" : "",
                }}
            >
                <PlannedEndTimeNotice shift={shift} hourWidth={hourWidth} />
                {shift.isRestore ? (
                    <div id={shift.id} style={{ position: "relative", display: "block" }}>
                        <ShiftBlock
                            hour={shift.hour}
                            type={shift.type}
                            color={shift.color}
                            background={shift.bg}
                            schedule={shift.sheduleName}
                            timeline={shift.timeline}
                            hasAlert={shift.isRestore == true ? "true" : false}
                            hasIncident={shift.incident != null ? shift.incident.type : false}
                            // containerClass='ReactVirtualized__Grid__innerScrollContainer'
                            isExempt={shift.isExempt}
                            routeCode={shift.routeCode}
                            sentToHome={shift.isSentHome}
                            refuseRescue={shift.isRefusedRescue}
                            wrapperId={wrapperId}
                            hasInvoice={shift.invoiceTypeId > 0}
                            hasCallOutOrNcns={shift.hasCallOutOrNcns}
                            isTimeOff={shift.isTimeOff}
                            hasAnySinglePunch={shift.hasAnySinglePunch}
                            isDeleted={shift.deleted}
                            isRestore={shift.isRestore}
                            category={shift.category}
                            handleDelete={handleDelete}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            handleOpenDelete={handleOpenDelete}
                        >
                            {({ open, setOpen }) => (
                                <ShiftBlockPopup
                                    shift={data[key]}
                                    open={{ open, setOpen }}
                                    actions={actions}
                                    schedule={shift.sheduleName}
                                    incidents={incidentTypes}
                                    viewType="day"
                                    isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                />
                            )}
                        </ShiftBlock>
                    </div>
                ) : shift.category == 10 ? (
                    <div id={shift.id} style={{ position: "relative", display: "block" }}>
                        <ShiftBlock
                            hour={shift.hour}
                            type={shift.type}
                            color={shift.color}
                            background={shift.bg}
                            schedule={shift.sheduleName}
                            timeline={shift.timeline}
                            hasAlert={shift.isRestore == true ? "true" : false}
                            hasIncident={shift.incident != null ? shift.incident.type : false}
                            // containerClass='ReactVirtualized__Grid__innerScrollContainer'
                            isExempt={shift.isExempt}
                            routeCode={shift.routeCode}
                            sentToHome={shift.isSentHome}
                            refuseRescue={shift.isRefusedRescue}
                            wrapperId={wrapperId}
                            hasInvoice={shift.invoiceTypeId > 0}
                            hasCallOutOrNcns={shift.hasCallOutOrNcns}
                            isTimeOff={shift.isTimeOff}
                            hasAnySinglePunch={shift.hasAnySinglePunch}
                            isDeleted={shift.deleted}
                            isRestore={shift.isRestore}
                            category={shift.category}
                            handleDelete={handleDelete}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            handleOpenDelete={handleOpenDelete}
                        >
                            {({ open, setOpen }) => (
                                <ShiftBlockPopup
                                    shift={data[key]}
                                    open={{ open, setOpen }}
                                    actions={actions}
                                    schedule={shift.sheduleName}
                                    incidents={incidentTypes}
                                    viewType="day"
                                    isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                />
                            )}
                        </ShiftBlock>
                    </div>
                ) : (
                    <ShiftDraggableHandler
                        shift={data[key]}
                        from={{ driverId: data.id, startTime: dataKey, type: TYPES.driver }}
                        selectedRows={selectedRows}
                        data={data}
                        dispatch={dispatch}
                        api={api}
                        state={state}
                        selectedValue={shift}
                        setSelectedRows={setSelectedRows}
                        schedulerApi={schedulerApi}
                        tableRef={tableRef}
                    >
                        <ShiftBlock
                            hour={shift.hour}
                            type={shift.type}
                            color={shift.color}
                            background={shift.bg}
                            schedule={shift.sheduleName}
                            timeline={shift.timeline}
                            hasAlert={shift.isNew == true ? "true" : false}
                            hasIncident={shift.incident != null ? shift.incident.type : false}
                            // containerClass='ReactVirtualized__Grid__innerScrollContainer'
                            isExempt={shift.isExempt}
                            routeCode={shift.routeCode}
                            sentToHome={shift.isSentHome}
                            refuseRescue={shift.isRefusedRescue}
                            wrapperId={wrapperId}
                            hasInvoice={shift.invoiceTypeId > 0}
                            hasCallOutOrNcns={shift.hasCallOutOrNcns}
                            isTimeOff={shift.isTimeOff}
                            hasAnySinglePunch={shift.hasAnySinglePunch}
                            category={shift.category}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            shift={shift}
                            driver={data}
                            handleDelete={handleDelete}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            handleOpenDelete={handleOpenDelete}
                        >
                            {({ open, setOpen }) => (
                                <ShiftBlockPopup
                                    shift={data[key]}
                                    open={{ open, setOpen }}
                                    actions={actions}
                                    schedule={shift.sheduleName}
                                    incidents={incidentTypes}
                                    viewType="day"
                                    isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                />
                            )}
                        </ShiftBlock>
                    </ShiftDraggableHandler>
                )}
            </ShiftBlockWrapper>
        );
    });
};

export default TableApp;


const PlannedEndTimeNotice = ({
    shift,
    hourWidth
}) => {
    if (!shift.plannedStartTime || !shift.plannedEndTime || shift.isRescuer || shift.isSentHome || shift.isTimeOff || (shift.incident && shift.incident.type !== 'Late'))
        return null;

    const plannedEndTimeWrapper = ShiftWrapper(shift.plannedEndTime, shift.plannedStartTime, hourWidth);
    const startPlacementWrapper = ShiftWrapper(shift.startTime, shift.plannedStartTime, hourWidth);
    const endPlacementWrapper = ShiftWrapper(shift.endTime, shift.plannedEndTime, hourWidth);
    const plannedStartTimeWrapperOffset = startPlacementWrapper.columnWidth * -1 + startPlacementWrapper.marginLeft;
    const plannedEndTimeWrapperOffset = endPlacementWrapper.columnWidth * -1 + endPlacementWrapper.marginLeft;

    const innerWidth = plannedEndTimeWrapper.columnWidth - plannedEndTimeWrapper.marginLeft - (shift.plannedStartTime < shift.startTime ? Math.abs(plannedStartTimeWrapperOffset) : 0) - (shift.plannedEndTime > shift.endTime ? Math.abs(plannedEndTimeWrapperOffset) : 0);

    return <>
        {shift.plannedStartTime < shift.startTime && <div
            style={{
                position: "absolute",
                width: Math.abs(plannedStartTimeWrapperOffset),
                right: `100%`,
                top: 0,
                bottom: 0,
                borderRadius: `${Theme.defaultRadius} 0 0 ${Theme.defaultRadius}`,
                zIndex: 6,
                border: `1px solid ${shift.bg}`,
                background: Theme.colors.neutrals.white,
                pointerEvents: "none",
                backgroundImage: `linear-gradient(110deg, ${shift.bg}36 25%, transparent 25%, transparent 50%, ${shift.bg}36 50%, ${shift.bg}36 75%, transparent 75%, transparent 100%)`,
                backgroundSize: `8.51px 23.39px`,
            }}
        />}
        <div
            style={{
                position: "absolute",
                // width: Math.max(plannedEndTimeWrapper.columnWidth - plannedEndTimeWrapper.marginLeft - (shift.plannedStartTime < shift.startTime ? Math.abs(plannedStartTimeWrapperOffset) : 0) - (shift.plannedEndTime > shift.endTime ? Math.abs(plannedEndTimeWrapperOffset) : 0), originalWrapper.columnWidth - plannedEndTimeWrapper.marginLeft),
                width: innerWidth,
                left: Math.max(startPlacementWrapper.marginLeft - startPlacementWrapper.columnWidth, 0),
                top: 0,
                bottom: 0,
                borderRadius: Theme.defaultRadius,
                zIndex: 6,
                // border: `1px solid ${shift.bg}`,
                pointerEvents: "none",
                backgroundImage: `linear-gradient(110deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent 100%)`,
                backgroundSize: `8.51px 23.39px`,
                // clipPath: `polygon(0% 0%, ${innerWidth - 10}px 0%, 100% 50%, ${innerWidth - 10}px 100%, 0% 100%)`
            }}
        >
            {/*{JSON.stringify([shift.startTime, shift.plannedStartTime, shift.endTime, shift.plannedEndTime, plannedEndTimeWrapper, startPlacementWrapper, endPlacementWrapper, originalWrapper])}*/}
        </div>
        {shift.plannedEndTime > shift.endTime && <div
            style={{
                position: "absolute",
                width: Math.abs(plannedEndTimeWrapperOffset),
                left: `100%`,
                top: 0,
                bottom: 0,
                borderRadius: `0 ${Theme.defaultRadius} ${Theme.defaultRadius} 0`,
                zIndex: 6,
                border: `1px solid ${shift.bg}`,
                background: Theme.colors.neutrals.white,
                pointerEvents: "none",
                backgroundImage: `linear-gradient(110deg, ${shift.bg}36 25%, transparent 25%, transparent 50%, ${shift.bg}36 50%, ${shift.bg}36 75%, transparent 75%, transparent 100%)`,
                backgroundSize: `8.51px 23.39px`,
            }}
        />}
    </>
}