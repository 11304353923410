import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Icon, Spacer, Theme, ThirdTitle, Input, Button } from "@dspworkplace/ui";
import ShiftBlock from "../../../components/ShiftBlock";
import { AutoSizer, Column, Table } from "react-virtualized";
import "react-virtualized/styles.css";
import { useAuth, getFriendlyName, getRole, getAllowsDispatcherPermission, getUserId} from "../../../Auth";
import { dialogPromise, dialog } from "../../../components/Dialog";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";

import WeekStore from "./context/weekStore";
import moment from "moment";
import { FooterColumnComponent, HeaderColumnComponent } from "./components/common.js";
import { confirm } from "../../../components/Confirm";
import { alert } from "../../../components/Alert";
import { showFlexibleShiftBlockForm } from "./components/shiftBlockForms";
import ES from "../../../EventSource";
import Driver from "../../../components/People";
import TimeFormat, { covertUtcToLocal, databaseToMilliseconds, getOffset } from "../../../components/TimeFormat";
import Loading, { LoadingWrapper } from "../../../components/Loading";
import ShiftBlockPopup from "../Common/ShiftPopUp";
import useSchedulerApi from "../Common/api";
import { useSelectedStation } from "../../../components/StationSelector";
import { FilterWeekDate } from "../../../hooks/useGlobalData";
import toast from "../../../components/Toasts/toast";
import { engine } from "../../../Utilities";
import Tooltip from "../../../components/Tooltip";
import { canModifyFuture, canModifyPast } from "../Common/AccessCheck";
import Details from "../../Employees/details";
import Modal from "../../../components/Modal";
import { useForm } from "react-hook-form";
import API from "../../SchedulerLoadOut/api.js";
import updateDispatch from "../Common/updateDispatch.js";
import { generateMultiEditReqBody } from "../Common/generateMultiReqBody.js";
import { dragMoveCopy } from "../Common/dragMoveCopy.js";
import { addEditDeletePopup } from "../Common/AddEditDeletePopup.js";
import { isEmptyWeekBoxNonSelectable, openCreateMultipleShiftPopup } from "../Common/emptyShiftBoxUtil.js";
import useGhostShiftApi from "../Common/GhostShiftCodeOptimize";
import axios from "axios";
import apiPromise from "../../Incidents/api";


const AutoSizerContainer = styled.div`
    width: 100%;
    height: calc(100vh - 150px);
    display: block;
    border: 1px solid #cccccc;
    border-radius: 2px;

    .WeekTable {
        flex: 1;

        *:focus {
            outline: none;
        }

        .ReactVirtualized__Table__Grid {
            //will-change: unset !important;
        }

        .ReactVirtualized__Table__headerRow {
            overflow: visible !important;
            cursor: pointer;
        }
        .ReactVirtualized__Table__row {
            border-bottom: 1px solid #cccccc;
            overflow: visible !important;
        }
        .ReactVirtualized__Table__rowColumn {
            margin: 0;
            //padding:8px;
            border-right: 1px solid #cccccc;
            height: 100%;
            overflow: visible !important;
        }
    }
`;

const HeaderStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#707070",
    textTransform: "capitalize",
    fontFamily: "circe-rounded",
    display: "flex",
    alignItems: "center",
    height: "100%",
    borderBottom: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    margin: "0",
};

const HeaderColumn = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
    flex-direction: column;
`;

const HeaderColumnOptions = styled.ul`
    width: auto;
    padding: 0;
    position: absolute;
    left: ${(props) => (props.direction === "left" ? "-1px" : "auto")};
    right: ${(props) => (props.direction === "right" ? "0px" : "auto")};
    top: 21px;
    list-style: none;
    z-index: 2;
    background: white;
    box-shadow: 0px 3px 6px ${Theme.colors.neutrals.gray}19;
    border: 1px solid #cccccc;
    border-radius: 2px;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;

    > li {
        padding: 0 8px;
        font-size: 14px;
        color: #707070;
        border-radius: 1px 1px 0px 0px;
        display: flex;
        align-items: center;
        height: 32px;
        white-space: nowrap;

        &.title {
            font-size: 12px;
            font-weight: bold;
        }

        &.group {
            border-top: 1px solid ${Theme.colors.neutrals.silver};
        }
    }
    > li:not(.title) {
        &:hover {
            background: #eaf0f6;
        }
    }
`;

const Hoverable = styled.div`
    > .picture{
        display: none;
    }
    &:hover {
        > .picture{
            display: block;
        }
    }
`;
const ProfileColumn = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .picture {
        // height:26px;
        // width:26px;
        // border-radius:100%;
        // border:1px solid ${Theme.colors.info.border};
        // background:${Theme.colors.info.bg};
        // display:flex;
        // justify-content:center;
        // align-items:flex-end;
        position: relative;
        // z-index:2;
        // margin-left:5px;

        ${(props) =>
        props.tooltip &&
        `
        &:before{
            position : absolute;
            content : attr(data-tooltip);
            opacity : 0;

            font-size:14px;
            background:#EAF0F6;
            color:#777777;
            padding:4px;
            border-radius:4px;
            pointer-events:none;
            z-index: 10;
            left:100%;
            top:0;
            width: 400px;
            overflow: visible;
            text-overflow: none;
            white-space: normal;
        }

        &:hover {
            &:before {
                opacity:1;
            }

        }

        `}
    }

    > .info {
        margin-left: 5px;
        h5 {
            font-size: 12px;
            font-family: circe-rounded;
            color: #333333;
            font-weight: normal;
        }
        > span {
            font-size: 12px;
            color: #707070;
        }
    }
`;

const FooterContainer = styled.div`
    margin-top: -2px;
    position: relative;
    z-index: 2;
    border-top: 4px solid #cccccc;
    background: #f5f5f5;

    > div {
        border-right: 1px solid #cccccc;
    }
`;

const FooterCol = styled.div`
    height: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -2px;
    position: relative;
    cursor: pointer;

    > div {
        display: flex;
        align-items: center;

        .text {
            font-size: 14px;
            color: #707070;

            &.error {
                color: #d94c53;
            }

            &.blue {
                color: #0071bc;
            }
        }

        > .popup {
            position: absolute;
            box-shadow: 0px 3px 6px #33333326;
            display: block;
            z-index: 10;
            
            .scroll {
                max-height: ${(props) => props.maxHeight + 'px' ?? 'auto'};
                overflow: auto;
                padding: 12px;
                background: white;
            }

            &:after {
                content: " ";
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #fff;
                position: absolute;
                left: 10px;
                bottom: -5px;
            }

            &.right {
                &:after {
                    left: auto;
                    right: 15px;
                }
            }
            
            .item {
                font-size: 14px;
                color: #333333;
                display: flex;
                justify-content: space-between;
                row-gap: 5px;
                min-width: 220px;
                padding-bottom: 12px;

                &:last-child {
                    padding-bottom: 0;
                }

                > div > span {
                    display: inline-block;
                }

                div > .circle {
                    width: 16px;
                    height: 16px;
                    border-radius: 100%;
                    background: black;
                    display: inline-block;
                }
            }
        }
    }
`;

const FooterWeek = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    top: -2px;
`;

const LWrap = styled(LoadingWrapper)`
    top: 120px;
`;

const SHIFT_ROW_HEIGHT = 47;
let dropSource;

const TableApp = ({ fakeRefresh, week, year }) => {
    const [fullState, dispatch] = useContext(WeekStore);
    let tableRef = useRef();
    const { api } = useAuth();
    const [curWeek, setCurWeek] = useState([]);
    let { state, filter, schedulerFooterData } = fullState;
    const [selectedStation] = useSelectedStation();
    let [weekDateM] = FilterWeekDate();
    const weekDate = moment(weekDateM).toDate();
    const [selectedRows, setSelectedRows] = useState([]);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [updateScheduler, setUpdatedScheduler] = fakeRefresh;
    const [expand, setExpand] = useState({ visible: false, id: "" });
    const [menuVisible, setMenuVisible] = useState(null);
    const {
        dropLoadoutShift, createOpenRouteTemp, createOpenRoute,
        getShiftOptions, checkMultipleDriverSkills, multiEditShift
    } = useSchedulerApi();
    const schedulerApi = useSchedulerApi();
    const station = JSON.parse(localStorage.getItem("station"))
    const cancelTokenRef = useRef(null);
    const CancelToken = axios.CancelToken;

    useEffect(() => {
        const handleClickOutside = (e) => {
            const tableContainer = document.querySelector('.table-container');
            if (!isEditFormOpen && tableContainer && !tableContainer.contains(e.target) && menuVisible) {
                setSelectedRows([]);
                dispatch({
                    type: "EMPTY_BOX_SELECT",
                    payload: false,
                });
            }
        };
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [isEditFormOpen]);

    const [publishUpdate] = fakeRefresh;

    state = filter;
    let openShiftInfo;

    if (state && state[0] && state[0].id === "0.0.0") {
        openShiftInfo = state[0];
        const originalState = [...state];
        originalState.shift();
        state = originalState;
    }

    const weekList = async (weekDate) => {
        let dayArr = [];
        for (var i = 0; i < 7; i++) {
            dayArr[i] = moment(weekDate).startOf("week").add(i, "days").format("ddd DD");
        }
        setCurWeek(dayArr);
    };
    const noteEdit = async (driverId, note, title) => {
        return dialogPromise((success, cancel) => (
            <NoteEditDailog success={success} cancel={cancel} dispatch={dispatch} title={title} note={note} driver={driverId} />
        ));
    };
    const fetchList = async () => {
        if (cancelTokenRef.current) {
           cancelTokenRef.current("Canceling the previous request");
        }
        weekDate.setHours("03");
        let week = moment(weekDate).isoWeek();
        if (moment(weekDate).day() === 0) {
            week = moment(weekDate).add(1, "days").isoWeek();
        }

        let lastDayOfCurrentSelectedWeek = moment(weekDate).endOf("week");
        let curentMonthOnLastDayOfSelectedWeek = lastDayOfCurrentSelectedWeek.month();
        let currentYearOfSelectedWeek = parseInt(moment(weekDate).endOf("week").format("YYYY"));
        if (week > 51 && curentMonthOnLastDayOfSelectedWeek == 0) {
            currentYearOfSelectedWeek = parseInt(moment(weekDate).startOf("week").format("YYYY"));
        }
        const params = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "getdriverFromSchedule",
                            get: "driverRoute",
                            excludes: [
                                "skill",
                                "vehicle",
                                "device",
                                "driver",
                                "station",
                                "shiftType",
                                "schedule",
                                "vehicleDriverRecord",
                                "status",
                                "tempDriverRoutes",
                                "kickoffLog",
                                "returnToStation",
                                "oldDriver",
                                "oldDriverRoute",
                                "driverRoutes",
                                "oldTempDriverRoutes",
                                "updatedBy",
                            ],
                            criteria: {
                                station: selectedStation,
                                wknumber: week,
                                timezoneOffset: getOffset(weekDate).offset,
                                year: currentYearOfSelectedWeek,
                            },
                        },
                    },
                },
            },
        };
        try {
            const response = await engine().post("/api/lazy/manage/data?when=" + fullState.lastModified, params, {
                cancelToken: new CancelToken((c) => {
                    cancelTokenRef.current = c;
                }),
                cache: {
                    exclude: {
                        query: false,
                    },
                },
            });

            await dispatch({
                type: "ADD",
                payload: {
                    stateData: response.data.data.driverRoute.schedulerRouteData,
                    filterOptions: response.data.data.driverRoute.schedulerFilterArray,
                    schedulerFooterData: response.data.data.driverRoute.schedulerFooterDataArray,
                    changes: response.data.data.driverRoute.changes,
                    deletes: response.data.data.driverRoute.hasDeleted,
                    selectedStation: selectedStation,
                },
            });

            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }

            /*let data = await API.fetchPendingChanges(
                {
                    station: selectedStation,
                    wknumber: week,
                    unpublishDataDate: null,
                    year: moment(new Date()).format("YYYY"),
                    type: "week"
                },
                api
            );
            let chnagesId = [];
            data.forEach(function(change) {
                chnagesId.push(change.id);
            });

            dispatch({
                type: "SET_PUBLISH_COUNT",
                ids: chnagesId,
                station: selectedStation,
                api: true
            });*/

            //document.getElementById("week-table-loading").style.display = "none";
        } catch (error) {
            console.error({ error });
            console.error("[ERROR]", error.message ? error.message : "");
        }

        apiPromise.cancelFetchFormOptions();
        apiPromise.fetchFormOptions({ station: selectedStation }).then(
            (response) => {
                dispatch({
                    type: "SET_INCIDENT_TYPES",
                    payload: response.data.data.incidentTypes,
                });
            },
            (error) => false
        );
    };

    const getAuthoriseCompleteIncident = async () => {
        let completeIncindetParam = {
            "actions": {
                "response": {
                    "Station": {
                        "custom": {
                            "functionName": "getUserAccessAuthoriseCompleteIncident",
                            "get": "result",
                            "criteria": {
                                "stationId":selectedStation,
                                "userId":getUserId()
                            }
                        }
                    }
                }
            }
        };
        let response = await api.post("/api/lazy/manage/data", completeIncindetParam);
        let result = response.data.data.result;
        if (result) {
            await dispatch({
                type: "SET_USER_ACCESS_AUTHORISE_INCIDENT",
                payload: result,
            });
        }
    }

    useEffect(() => {
        if (!selectedStation) {
            return;
        }

        if (weekDate instanceof Date) {
            // if (!fullState.loading) dispatch({ type: "LOADING", payload: { status: true } });
            dispatch({ type: "LOADING", payload: { status: true } });
            fetchList();
            weekList(weekDate);
            return () => {
                if (cancelTokenRef.current) {
                    cancelTokenRef.current("canceling request");
                }
            };
        }
    }, [weekDateM, selectedStation]);

    useEffect(() => {
        if (publishUpdate < 1) return;
        if (weekDate instanceof Date) {
            dispatch({ type: "LOADING", payload: { status: true } });
            fetchList();
            weekList(weekDate);
            return () => {
                if (cancelTokenRef.current) {
                    cancelTokenRef.current("canceling request");
                }
            };
        }
    }, [publishUpdate]);

    useEffect(() => {
        getAuthoriseCompleteIncident();
        const es = new ES(`scheduler`);

        const parseToLocalDate = (shift) => {
            const timezone = new Date().getTimezoneOffset() / -60;
            if (typeof shift.shiftDate != "undefined") {
                timezone = new Date(shift.shiftDate).getTimezoneOffset() / -60;
            } else if (typeof shift.dateCreated != "undefined") {
                timezone = new Date(shift.dateCreated).getTimezoneOffset() / -60;
            }
            shift.startTime += timezone;
            shift.endTime += timezone;
            shift.hour =
                moment(shift.startTime, "HH.mm").format("hh:mma") +
                "-" +
                moment(shift.endTime, "HH.mm").format("hh:mma");
            return shift;
        };

        const dispatcherRules = (reference) => {
            switch (reference.type) {
                case "SHIFT_ADDED":
                    return "ADDED";
                    break;
                case "SHIFT_MOVED":
                    return "MOVED";
                    break;
                case "SHIFT_DELETED":
                    return "REMOVED";
                    break;
                case "SHIFT_EDITED":
                    return "UPDATE";
                    break;
                case "SHIFT_REMOVED":
                    return "REMOVED";
                    break;
                default:
                    return false;
            }
        };

        es.onmessage = (data) => {
            try {
                // TODO: Throw Type Error of undefined for getting week
                if (data.data.reference.week != week) return false;
                const eventType = dispatcherRules(data.data.reference);
                if (eventType) {
                    data.data.item = parseToLocalDate(data.data.item);

                    dispatch({
                        type: eventType,
                        payload: data.data,
                    });

                    tableRef.current.recomputeRowHeights();
                }
            } catch (e) {
                toast({
                    type: "Error",
                    title: "Shift Error",
                    content: e.message,
                }).then();
            }
        };

        return () => {
            es.close();
        };
    }, [selectedStation]);

    const onRemoveMultiRoute = (oldShifts) => {
        oldShifts.forEach(oldShift => {
            let newShift = {
                ...oldShift,
                deleted: true,
                deletedBy: getFriendlyName(),
                deletedAt: moment().format("MM/DD/YYYY") + " at " + moment().format("h:mm a"),
            };
            if ((oldShift.hasAlert && oldShift.hasAlert == true) || oldShift.driverId == "0.0.0") {
                dispatch({
                    type: "REMOVED",
                    payload: {
                        from: {
                            driverId: oldShift.driver.id,
                            week: moment(oldShift.shiftDate).weekday(),
                            id: oldShift.id,
                        },
                        item: {
                            hasAlert: false,
                        },
                    },
                });
            } else {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        from: {
                            driverId: oldShift.driverId,
                            week: moment(oldShift.shiftDate).weekday(),
                            id: oldShift.id,
                        },
                        to: {
                            driverId: newShift.driverId,
                            week: moment(newShift.shiftDate).weekday(),
                        },
                        item: newShift,
                        hasAlert: false,
                        addChange: false,
                    },
                });
            }
        });

        toast({
            type: "info",
            title: "Remove changes…",
            content: "You can continue your work while we remove your selected changes.",
        });
        setUpdatedScheduler(updateScheduler + 1);

        oldShifts.forEach(oldShift => {
            dispatch({ type: "REMOVE_CHANGES", id: oldShift.id, station: oldShift.stationId });
        });
    }

    const handleDelete = async (data, isMultiple) => {
        const driverRouteId = [];
        selectedRows.map((e) => {
            driverRouteId.push(e.routeId)
        })
        setIsEditFormOpen(true);
        const conf = await confirm({
            icon: true,
            text: isMultiple ?
                "WARNING: You are about delete shifts for multiple drivers! <br /> This will remove the selected driver's shifts from the schedule on the day(s) you have selected." :
                "This will treat this Shift as a scheduling error and remove only the driver's shift from today's record.",
            notificationText: "Notify driver of the shift's removal via SMS"
        });

        if (!conf) {
            setSelectedRows([]);
            dispatch({
                type: "EMPTY_BOX_SELECT",
                payload: false,
            });
            setIsEditFormOpen(false);
            return;
        }

        const { sendNotification: notifyDriver } = conf;

        await dropLoadoutShift({
            driverId: selectedRows.length > 0 ? driverRouteId : data.routeId,
            incidentId: null,
            notifyDriver: notifyDriver,
            isMultiDelete: true,
        });

        await onRemoveMultiRoute(selectedRows)
        setSelectedRows([]);
        dispatch({
            type: "EMPTY_BOX_SELECT",
            payload: false,
        });

    }

    const moveToOpenRoute = (oldShifts) => {
        oldShifts.forEach(oldShift => {
            oldShift.driverId = "0.0.0";
            oldShift.isOpenShift = true;
            dispatch({
                type: "MOVED",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        routeId: oldShift.id,
                    },
                    to: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                    },
                    item: oldShift,
                    hasAlert: false,
                },
            });
        })
        onRemoveMultiRoute(selectedRows);
        if (tableRef && tableRef.current) {
            tableRef.current.recomputeRowHeights();
        }
    }

    const handleOpenDelete = async (data) => {
        const driverRouteId = [];
        selectedRows && selectedRows.map((e) => {
            driverRouteId.push(e.routeId)
        })
        const openRouteConfi = await confirm({
            icon: true,
            text: "This will treat this Shift as a scheduling error and remove it from today's record but will place the Route Code into Open Routes.",
        });
        if (!openRouteConfi) return;
        if (data.isNew) {
            await createOpenRouteTemp({
                driverId: selectedRows.length > 0 ? driverRouteId : data.routeId,
                incidentId: null,
                isMultiDelete: selectedRows.length > 0 ? true : false
            });
        } else {
            await createOpenRoute({
                driverId: selectedRows.length > 0 ? driverRouteId : data.routeId,
                incidentId: null,
                isMultiDelete: selectedRows.length > 0 ? true : false
            });
        }
        moveToOpenRoute(selectedRows);
        setSelectedRows([]);
        dispatch({
            type: "EMPTY_BOX_SELECT",
            payload: false,
        });
        return toast({
            type: "success",
            title: "Shift and Route successfully moved in open route.",
        });
    }

    const handleMultipleShiftEdit = async () => {
        const filteredShifts = selectedRows.filter(shift => shift.isTrain || shift.isDuty);
        const filteredIsTrain = filteredShifts.find(shift => shift?.isTrain);
        const filteredIsDuty = filteredShifts.find(shift => shift?.isDuty);

        const response = await getShiftOptions({
            shift: {
                ...selectedRows[0], isTrain: filteredIsTrain?.isTrain, isDuty: filteredIsDuty?.isDuty,
                isEditTrainDutyShift: filteredIsDuty?.isEditTrainDutyShift
            },
        });

        let invoiceTypes = response.data.data.invoiceTypes;

        let ShiftTypeOption = response.data.data.shift;
        ShiftTypeOption = [{ value: "mismatch", station: station, id: "mismatch", name: "(multiple values selected)" }, ...ShiftTypeOption];
        invoiceTypes = [{ value: "mismatch", station: station, id: "mismatch", name: "(multiple values selected)" }, ...invoiceTypes];

        const matchingShiftType = selectedRows.every((row, index, array) => (row.type === array[0].type));
        const matchingInvoiceType = selectedRows.every((row, index, array) => (row.invoiceTypeId === array[0].invoiceTypeId));
        const rowData = selectedRows[0]
        setIsEditFormOpen(true);
        showFlexibleShiftBlockForm({
            options: {
                shiftTypes: ShiftTypeOption,
                shiftInvoiceTypes: invoiceTypes,
                loaded: true,
                diffTime: matchingShiftType ? false : true,
                diffshiftInvoiceType: matchingInvoiceType ? false : true
            },
            timeZoneShortName: rowData?.timezone,
            shift: {
                ...rowData,
                routeId: rowData.id,
                isDuty: false,
                typeId: matchingShiftType ? parseFloat(selectedRows[0].typeId) : 'mismatch',
                startTime: matchingShiftType ? parseFloat(selectedRows[0].startTime) : 'mismatch',
                endTime: matchingShiftType ? parseFloat(selectedRows[0].endTime) : 'mismatch',
                utcStartTime: matchingShiftType ? parseFloat(selectedRows[0].utcStartTime) : 'mismatch',
                utcEndTime: matchingShiftType ? parseFloat(selectedRows[0].utcEndTime) : 'mismatch',
            },
        }).then(async (data) => {
            if (!data) return;
            try {
                const responseDriverSkill = await checkMultipleDriverSkills(selectedRows, data);
                if (responseDriverSkill.data.data.result.some(res => !res.result)) {
                    const confirmation = await confirm(
                        `WARNING: Few drivers does not have the required skill to be assigned this shift type. Are you sure you want to continue?`
                    );
                    if (!confirmation) {
                        return true;
                    }
                }
                const response = await multiEditShift(selectedRows, data, [], '');
                const oldShift = selectedRows;
                const newShift = response.data.data.tempDriverRoute;
                const rescuer = response.data.data.tempDriverRoute;
                const criteria = generateMultiEditReqBody(selectedRows, data);
                updateDispatch(oldShift, newShift, criteria, rescuer, dispatch, fullState);
            } catch (error) {
                console.error('Error: multiCheckDriverAndShiftSkillMatch', error);
            }
        }).catch((error) => {
            console.error("Error in showFlexibleShiftBlockForm:", error);
        }).finally(() => {
            setSelectedRows([]);
            dispatch({
                type: "EMPTY_BOX_SELECT",
                payload: false,
            });
            setIsEditFormOpen(false);
        });
    }

    const handleShiftKeyEventWeek = async (e) => {
        if (e.key === 'Shift' && selectedRows?.length > 0) {
            if (fullState.isEmptyBoxSelected) {
                const response = await openCreateMultipleShiftPopup(fullState, selectedRows, api, schedulerApi, false, weekDateM);
                dispatch({
                    type: "EMPTY_BOX_SELECT",
                    payload: false,
                });
                if (response) {
                    selectedRows.forEach((driver, index) => {
                        dispatch({
                            type: "ADDED",
                            payload: {
                                to: {
                                    driverId: driver.id || "0.0.0",
                                    week: moment(driver.date).weekday(),
                                },
                                item: response.data.data.tempDriverRoute[index],
                                hasAlert: true,
                            },
                        });
                    });

                    toast({
                        type: "success",
                        title: "Shift Created",
                        content: "You must publish your changes.",
                    });

                    tableRef.current.recomputeRowHeights();
                }
                setSelectedRows([]);
                return;
            }
            addEditDeletePopup().then(data => {
                if (data === 'add-edit') {
                    handleMultipleShiftEdit()
                } else if (data === 'delete') {
                    handleDelete({}, true)
                } else {
                    setSelectedRows([])
                    dispatch({
                        type: "EMPTY_BOX_SELECT",
                        payload: false,
                    });
                }
            })
        }
    }

    if (fullState.loading) {
        return (
            <LWrap id="week-table-loading">
                <Loading style={{ width: 40 }} />
            </LWrap>
        );
    }

    if (!selectedStation) {
        return (
            <ThirdTitle style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                To use scheduler it's necessary choose one station
            </ThirdTitle>
        );
    }

    return (
        <AutoSizerContainer>
            <AutoSizer>
                {({ height, width }) => {
                    if (width < 1) return false;
                    let DriverColumnWidth = 185;
                    let FooterHeight = 34;
                    let WeekColumnsWidth = (width - DriverColumnWidth) / 7;
                    let openShiftSize =
                        SHIFT_ROW_HEIGHT *
                        (openShiftInfo
                            ? openShiftInfo.status
                                ? openShiftInfo.biggerWeek
                                : openShiftInfo.groupQty || 1
                            : 1);
                    let openShiftOverflow = false;

                    if (openShiftSize > height * 0.42) {
                        openShiftSize = height * 0.42;
                        openShiftOverflow = true;
                    }
                    openShiftInfo = openShiftInfo ? openShiftInfo : {};
                    if (openShiftSize < SHIFT_ROW_HEIGHT) {
                        openShiftSize = SHIFT_ROW_HEIGHT;
                    }
                    return (
                        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                            <div className="table-container" style={{ userSelect: 'none', outline: 'none' }} tabIndex="0" onKeyUp={handleShiftKeyEventWeek}>
                                <Table
                                    ref={tableRef}
                                    className="WeekTable"
                                    height={height - FooterHeight}
                                    width={width}
                                    headerHeight={36 + openShiftSize}
                                    headerStyle={HeaderStyle}
                                    rowCount={state.length}
                                    rowHeight={({ index }) => {
                                        return SHIFT_ROW_HEIGHT * (state[index].biggerWeek || 1);
                                    }}
                                    rowGetter={({ index }) => {
                                        return state[index];
                                    }}
                                >
                                    <Column
                                        dataKey="driver"
                                        label="Driver"
                                        width={DriverColumnWidth}
                                        headerRenderer={(props) =>
                                            HeaderElement(
                                                props,
                                                DriverColumnWidth,
                                                null,
                                                openShiftInfo,
                                                dispatch,
                                                openShiftOverflow,
                                                year,
                                                fakeRefresh,
                                                selectedRows,
                                                setSelectedRows
                                            )
                                        }
                                        cellRenderer={({ rowData, rowIndex }) => (
                                            <DriverRender
                                                rowData={rowData}
                                                rowIndex={rowIndex}
                                                onClick={() => setExpand({ visible: true, id: rowData.id })}
                                                onNoteAdd={() => { noteEdit(rowData.driverId, rowData.notes, 'Note Add') }}
                                                onNoteEdit={() => { noteEdit(rowData.driverId, rowData.notes, 'Note Edit') }}
                                                selectedRows={selectedRows}
                                                setSelectedRows={setSelectedRows}
                                                setIsEditFormOpen={setIsEditFormOpen}
                                                updateScheduler={updateScheduler}
                                                setUpdatedScheduler={setUpdatedScheduler}
                                            />
                                        )}
                                    />
                                    {curWeek.map((item, index) => {
                                        return (
                                            <Column
                                                dataKey={"w" + index}
                                                label={item}
                                                width={WeekColumnsWidth}
                                                key={index}
                                                headerRenderer={(props) =>
                                                    HeaderElement(
                                                        props,
                                                        WeekColumnsWidth,
                                                        openShiftInfo[`w${index}`],
                                                        openShiftInfo,
                                                        dispatch,
                                                        openShiftOverflow,
                                                        year,
                                                        fakeRefresh,
                                                        selectedRows,
                                                        setSelectedRows,
                                                        tableRef
                                                    )
                                                }
                                                cellRenderer={(props) =>
                                                    WeekRender({ ...props, selectedRows, setSelectedRows }, tableRef, dispatch, fullState, engine,
                                                        fakeRefresh, updateScheduler, setUpdatedScheduler, handleDelete, menuVisible, setMenuVisible, handleOpenDelete)
                                                }
                                            />
                                        );
                                    })}
                                </Table>

                                <Footer
                                    height={FooterHeight}
                                    width={width}
                                    firstColWidth={DriverColumnWidth}
                                    colWidth={WeekColumnsWidth}
                                    data={schedulerFooterData}
                                    totalDrivers={state.length}
                                    tableRef={tableRef}
                                />

                                {expand.visible && (
                                    <Details
                                        profileId={expand.id}
                                        detailVisible={expand.visible}
                                        toggleDetails={() => {
                                            setExpand({ ...expand, visible: false });
                                        }}
                                        modalHandler={() => { }}
                                        isEdit="false"
                                    />
                                )}
                            </div>
                        </DndProvider>
                    );
                }}
            </AutoSizer>
        </AutoSizerContainer>
    );
};

const HeaderElement = (
    { label, dataKey },
    width,
    openShifts,
    rowData,
    dispatch,
    openShiftOverflow,
    year,
    fakeRefresh,
    selectedRows,
    setSelectedRows,
    tableRef
) => {
    let options = [
        {
            fields: ["firstName"],
            weekFields: ["startTime"],
            dataKey,
            label: "Start Time",
        },
        {
            fields: ["firstName"],
            weekFields: ["type"],
            dataKey,
            label: "Shift Type",
        },
        {
            fields: [""],
            weekFields: ["routeCode"],
            dataKey,
            label: "Route Code",
        },
        // {fields:['firstName'],dataKey,label:'Set as holiday/non-business day','data_elem':'holiday_week'}
    ];

    let dayClass = "driver";
    if (document.querySelector(".DayPickerInput input") && dataKey !== "driver") {
        let week = document.querySelector(".DayPickerInput input").value.replace(/[^\d]/g, "");
        let today = moment().format("YYYY-MM-DD");

        let thisDay = moment().isoWeek(week).isoWeekYear(year).weekday(dataKey.replace("w", "")).format("YYYY-MM-DD");

        dayClass = today == thisDay ? "today" : today > thisDay ? "pastday" : "futureday";
    }
    if (dayClass !== "pastday")
        options.push({
            fields: ["firstName"],
            dataKey,
            label: "Unassign all Shifts",
            data_elem: "unschedule",
            className: "group",
        });

    options.push({
        fields: ["firstName"],
        dataKey,
        label: "Delete all Open Shifts",
        data_elem: "allOpenShift",
        className: "group",
    });

    let direction = "left";
    if (dataKey == "w6") {
        direction = "right";
    }

    if (dataKey === "driver") {
        options = [
            { fields: ["firstName", "workHours"], label: "First Name" },
            { fields: ["lastName", "workHours"], label: "Last Name" },
            { fields: ["workHours", "firstName"], label: "Hours Worked" },
            { fields: ["scheduleName", "firstName"], label: "Schedule Name" },
        ];
    }

    return (
        <HeaderColumn>
            <HeaderColumnComponent
                label={label}
                Wrapper={HeaderColumnOptions}
                colWidth={width}
                popUpDirection={direction}
                options={options}
                dataKey={dataKey}
            />
            {dataKey === "driver" && <UnshiftDriver data={rowData} patch={dispatch} />}
            {openShifts && (
                <WeekRender
                    cellData={openShifts}
                    openShiftOverflow={openShiftOverflow}
                    dataKey={dataKey}
                    rowIndex={0}
                    columnIndex={dataKey}
                    rowData={rowData}
                    tableRef={tableRef}
                    dispatch={dispatch}
                    fakeRefresh={fakeRefresh}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
            )}
        </HeaderColumn>
    );
};

const DriverRender = ({ rowData, rowIndex, onClick, onNoteAdd, onNoteEdit,
    setIsEditFormOpen, updateScheduler, setUpdatedScheduler, ...props }) => {
    let name = (rowData?.driver) ? rowData.driver : "";
    const totalString = 13;
    let hasTooltip = false;
    if (name.length > totalString) {
        name = name.slice(0, totalString) + "...";
        hasTooltip = true;
    }

    let spanColor = false;
    if (rowData.workHours > 40 || rowData.estimatedWorkHours > 40) {
        spanColor = true;
    }


    return (
        <ProfileColumn style={{ height: "100%", padding: "8px" }}>
            <ProfileColumn tooltip={hasTooltip} style={{ width: "100%" }} onClick={onClick}>
                <div
                    className="picture"
                    data-tooltip={rowData.driver}
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
                >
                    <Driver
                        name={name}
                        jobTitle={
                            <span style={{ display: "flex", alignItems: "center", marginTop: "" }}>
                                <Icon.Clock size="12px" color="#707070" />
                                <span style={spanColor ? { color: "#D81A20" } : {}}>{rowData.workHours}</span>/{" "}
                                <span style={spanColor ? { color: "#D81A20" } : {}}>{rowData.estimatedWorkHours}</span>
                            </span>
                        }
                        size="20px"
                        img={rowData.profile ? rowData.profile : ""}
                    />
                    {rowData.driverShiftsCount >= 5 && (
                        <span style={{ fontSize: 12, color: Theme.colors.error.text }}>
                            {rowData.driverShiftsCount}th
                        </span>
                    )}
                </div>
            </ProfileColumn>
            {rowData.notes ? (
                <ProfileColumn tooltip={true} onClick={() => { onNoteEdit() }}>
                    <div className="picture" data-tooltip={rowData.notes}>
                        <svg fill={Theme.colors.info.border} height="20px" width="20px" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 498.93"><path d="M389.76 0c33.74 0 64.31 13.69 86.42 35.8C498.31 57.92 512 88.5 512 122.24c0 33.75-13.69 64.33-35.8 86.44-22.13 22.12-52.7 35.82-86.44 35.82-33.71 0-64.27-13.68-86.4-35.82-22.19-22.2-35.86-52.76-35.86-86.44 0-33.74 13.69-64.32 35.82-86.44C325.43 13.69 356.01 0 389.76 0zM127.13 93.07 45.4 179.44h81.73V93.07zM0 195.82c0-1.15.15-2.27.41-3.35.13-3.14 1.36-6.27 3.69-8.73L128.47 52.32c2.31-4.18 6.78-7.01 11.9-7.01h113.41c-4.87 8.6-8.97 17.7-12.16 27.2h-87.3v120.52c0 7.5-6.09 13.59-13.59 13.59H27.2v265.11h392.02V275.68c9.4-1.8 18.49-4.44 27.21-7.83V471.9c0 7.39-3.05 14.15-7.93 19.05-4.96 4.94-11.72 7.98-19.11 7.98H27.04c-7.43 0-14.21-3.06-19.09-7.94C3.07 486.1 0 479.32 0 471.9V195.82zm324.89 79.39c7.53 0 13.66 6.05 13.66 13.52s-6.13 13.52-13.66 13.52H121.54c-7.54 0-13.66-6.05-13.66-13.52s6.12-13.52 13.66-13.52h203.35zm0 91.23c7.53 0 13.66 6.05 13.66 13.52s-6.13 13.52-13.66 13.52H121.54c-7.54 0-13.66-6.05-13.66-13.52s6.12-13.52 13.66-13.52h203.35zm63.8-204.34-46.97 12.52 6.77-50.42 40.2 37.9zm-29.84-49.34 46.13-52.62c.77-.91 1.92-1.44 3.1-1.48.74-.01 1.48.14 2.13.5l35.78 32.53c.71.66 1.14 1.59 1.15 2.56.04 1.09-.41 2.16-1.25 2.88l-46.97 53.52-40.13-37.89h.06z" /></svg>
                    </div>
                </ProfileColumn>
            ) : (
                <ProfileColumn tooltip={false} onClick={() => { onNoteAdd() }}>
                    <Hoverable style={{ minWidth: "20px", minHeight: "20px" }} >
                        <div className="picture" data-tooltip={rowData.notes}>
                            <svg fill={Theme.colors.info.border} height="20px" width="20px" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 498.93"><path d="M389.76 0c33.74 0 64.31 13.69 86.42 35.8C498.31 57.92 512 88.5 512 122.24c0 33.75-13.69 64.33-35.8 86.44-22.13 22.12-52.7 35.82-86.44 35.82-33.71 0-64.27-13.68-86.4-35.82-22.19-22.2-35.86-52.76-35.86-86.44 0-33.74 13.69-64.32 35.82-86.44C325.43 13.69 356.01 0 389.76 0zM127.13 93.07 45.4 179.44h81.73V93.07zM0 195.82c0-1.15.15-2.27.41-3.35.13-3.14 1.36-6.27 3.69-8.73L128.47 52.32c2.31-4.18 6.78-7.01 11.9-7.01h113.41c-4.87 8.6-8.97 17.7-12.16 27.2h-87.3v120.52c0 7.5-6.09 13.59-13.59 13.59H27.2v265.11h392.02V275.68c9.4-1.8 18.49-4.44 27.21-7.83V471.9c0 7.39-3.05 14.15-7.93 19.05-4.96 4.94-11.72 7.98-19.11 7.98H27.04c-7.43 0-14.21-3.06-19.09-7.94C3.07 486.1 0 479.32 0 471.9V195.82zm324.89 79.39c7.53 0 13.66 6.05 13.66 13.52s-6.13 13.52-13.66 13.52H121.54c-7.54 0-13.66-6.05-13.66-13.52s6.12-13.52 13.66-13.52h203.35zm0 91.23c7.53 0 13.66 6.05 13.66 13.52s-6.13 13.52-13.66 13.52H121.54c-7.54 0-13.66-6.05-13.66-13.52s6.12-13.52 13.66-13.52h203.35zm53.26-302.36h23.25c4.48 0 8.15 3.75 8.15 8.14v30.27h30.24c4.49 0 8.15 3.79 8.15 8.14v23.24c0 4.4-3.75 8.15-8.15 8.15h-30.24v30.27c0 4.38-3.75 8.13-8.15 8.13h-23.25c-4.39 0-8.14-3.64-8.14-8.13v-30.27h-30.29c-4.4 0-8.15-3.66-8.15-8.15v-23.24c0-4.49 3.66-8.14 8.15-8.14h30.29V72.22c0-4.48 3.65-8.14 8.14-8.14z" /></svg>
                        </div>
                    </Hoverable>
                </ProfileColumn>
            )}
        </ProfileColumn>
    );
};

const UnshiftDriver = ({ data }) => {
    const [state, dispatch] = useContext(WeekStore);

    const onClickHandler = () => {
        dispatch({ type: "EXPAND_OPEN_SHIFTS" });
        if (state.refTable.current) {
            state.refTable.current.recomputeRowHeights();
        }
    };

    return (
        <div
            style={{
                padding: 8,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
                width: "100%",
            }}
            onClick={() => {
                onClickHandler();
            }}
        >
            <div>
                <ThirdTitle style={{ fontWeight: "400", color: "#333333" }}>Open Shifts</ThirdTitle>
                <Tooltip verticalPosition={"center"} horizontalPosition={"right"} content={"Confirmed Shifts"}>
                    <span
                        style={{
                            fontSize: 12,
                            color: "#707070",
                            display: "inline-flex",
                            alignItems: "center",
                        }}
                    >
                        <Icon.CheckSquare size="12px" color="#707070" style={{ marginRight: "4px" }} />
                        {data ? data.qty : 0}
                    </span>
                </Tooltip>
                <Spacer left={3} inline>
                    <Tooltip verticalPosition={"center"} horizontalPosition={"right"} content={"Shift Requests"}>
                        <span
                            style={{
                                fontSize: 12,
                                color: "#707070",
                                display: "inline-flex",
                                alignItems: "center",
                            }}
                        >
                            <Icon.Warning size="12px" color="#707070" style={{ marginRight: "4px" }} />
                            {data ? data.shift_requests : 0}
                        </span>
                    </Tooltip>
                </Spacer>
            </div>
            {data && data.status === 0 ? (
                <Icon.ArrowDown size="16px" color="#7C98B6" style={{ cursor: "pointer" }} />
            ) : (
                <Icon.ArrowUp size="16px" color="#7C98B6" style={{ cursor: "pointer" }} />
            )}
        </div>
    );
};

const WeekRender = (
    { cellData, rowIndex, columnIndex, dataKey, rowData, openShiftOverflow, selectedRows, setSelectedRows, ...props },
    tableRef,
    dispatch,
    state,
    engine,
    fakeRefresh,
    updateScheduler,
    setUpdatedScheduler,
    handleDelete,
    menuVisible,
    setMenuVisible,
    handleOpenDelete
) => {
    if (!dispatch && props.dispatch) {
        dispatch = props.dispatch;
    }

    if (!tableRef.current && props.tableRef && props.tableRef.current) {
        tableRef = props.tableRef;
    }

    if (rowData.id === "0.0.0" && cellData.length > 1 && rowData.status === 0) {
        return (
            <ShiftDroppable
                tableRef={tableRef}
                data={{ accept: rowData.skills }}
                stateKey={[rowData.id, dataKey]}
                driver={{
                    id: rowData.id,
                    stationId: rowData.stationId,
                }}
                style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: rowData.groupQty > 1 ? "space-between" : "center",
                }}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                isBoxEmpty={false}
            >
                <UnshiftWeekRender shifts={cellData} rowData={rowData} dataKey={dataKey} dispatch={dispatch} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
            </ShiftDroppable>
        );
    }

    const actions = {
        onEditShift(oldShift, newShift) {
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        id: oldShift.id,
                    },
                    to: {
                        driverId: newShift.driverId,
                        week: moment(newShift.shiftDate).weekday(),
                    },
                    item: newShift,
                    hasAlert: true,
                    addChange: true,
                },
            });

            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },

        onEditShiftNote: (oldShift, newShift) => {
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        id: oldShift.id,
                    },
                    to: {
                        driverId: newShift.driverId,
                        week: moment(newShift.shiftDate).weekday(),
                    },
                    item: newShift,
                    hasAlert: false,
                    addChange: false,
                },
            });

            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },
        onDropRoute(oldShift, result) {
            dispatch({
                type: "REMOVED",
                payload: {
                    from: {
                        driverId: oldShift.driver.id,
                        week: moment(oldShift.shiftDate).weekday(),
                        id: oldShift.id,
                    },
                    item: {
                        hasAlert: true,
                    },
                },
            });
        },
        async onDropRescue(shift, rescuerDriverRoute, rescuedDriverRoutes) {
            dispatch({
                type: "REMOVED",
                payload: {
                    from: {
                        driverId: shift.driver.id,
                        week: moment(shift.shiftDate).weekday(),
                        id: shift.id,
                    },
                    item: {
                        hasAlert: false,
                    },
                },
            });
            dispatch({ type: "REMOVE_CHANGES", id: shift.id, station: shift.stationId });

            if (rescuerDriverRoute) {
                dispatch({
                    type: "UPDATE_RESCUER",
                    payload: {
                        from: {
                            driverId: rescuerDriverRoute.driverId,
                            week: moment(rescuerDriverRoute.shiftDate).weekday(),
                            id: rescuerDriverRoute.id,
                        },
                        to: {
                            driverId: rescuerDriverRoute.driverId,
                            week: moment(rescuerDriverRoute.shiftDate).weekday(),
                        },
                        item: rescuerDriverRoute,
                        hasAlert: false,
                        addChange: false,
                    },
                });
            }

            if (rescuedDriverRoutes.length > 0) {
                rescuedDriverRoutes.map((rescuedDriverRoute) => {
                    dispatch({
                        type: "UPDATE_RESCUED",
                        payload: {
                            from: {
                                driverId: rescuedDriverRoute.driverId,
                                week: moment(rescuedDriverRoute.shiftDate).weekday(),
                                id: rescuedDriverRoute.id,
                            },
                            to: {
                                driverId: rescuedDriverRoute.driverId,
                                week: moment(rescuedDriverRoute.shiftDate).weekday(),
                            },
                            hasAlert: false,
                            addChange: false,
                        },
                    });
                });
            }
        },
        //Save add train and light duty
        onAddTrainer(oldShift, result) {
            let driver = {};
            let shift = {};

            if (oldShift.isTrain) {
                oldShift.trainee.forEach((trainee) => {
                    driver = state.state.find((d) => d.id == trainee.driverId);
                    shift = driver["w" + moment(oldShift.shiftDate).weekday()].find((r) => r.id == trainee.id);
                    shift.priorShiftRouteId = null;
                    shift.priorShiftType = "";
                    dispatch({
                        type: "UPDATE",
                        payload: {
                            from: {
                                driverId: shift.driverId,
                                week: moment(shift.shiftDate).weekday(),
                                id: shift.id,
                            },
                            to: {
                                driverId: shift.driverId,
                                week: moment(shift.shiftDate).weekday(),
                            },
                            item: shift,
                            hasAlert: false,
                        },
                    });
                });
            }

            if (result.isTrain) {
                result.trainee.forEach((trainee) => {
                    driver = state.state.find((d) => d.id == trainee.driverId);
                    shift = driver["w" + moment(result.shiftDate).weekday()].find((r) => r.id == trainee.id);
                    // trainee.profile = driver.profile;
                    shift.paired = [
                        {
                            id: oldShift.id,
                            driverId: oldShift.driver.id,
                            driverName: oldShift.driver.name,
                            profile: oldShift.driver.img,
                        },
                    ];
                    shift.priorShiftRouteId = result.id;
                    shift.priorShiftType = "Trainer";
                    let msg =
                        moment().format("YYYY-MM-DD") +
                        " => Trained by " +
                        oldShift.driver.name +
                        " (assigned by " +
                        localStorage.friendlyName +
                        ")";
                    shift.newNote = shift.newNote + msg;
                    dispatch({
                        type: "UPDATE",
                        payload: {
                            from: {
                                driverId: shift.driverId,
                                week: moment(shift.shiftDate).weekday(),
                                id: shift.id,
                            },
                            to: {
                                driverId: shift.driverId,
                                week: moment(shift.shiftDate).weekday(),
                            },
                            item: shift,
                            hasAlert: false,
                        },
                    });
                });
            }

            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: result.driverId,
                        week: moment(result.shiftDate).weekday(),
                        id: result.id,
                    },
                    to: {
                        driverId: result.driverId,
                        week: moment(result.shiftDate).weekday(),
                    },
                    item: result,
                    hasAlert: false,
                },
            });
            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },
        onAddRescue(oldShift, result, rescuedShifts) {
            if (rescuedShifts.length > 0) {
                rescuedShifts.map((rescuedShift) => {
                    dispatch({
                        type: "UPDATE",
                        payload: {
                            from: {
                                driverId: rescuedShift.driverId,
                                week: moment(rescuedShift.shiftDate).weekday(),
                                id: rescuedShift.id,
                            },
                            to: {
                                driverId: rescuedShift.driverId,
                                week: moment(rescuedShift.shiftDate).weekday(),
                            },
                            item: rescuedShift,
                            hasAlert: rescuedShift.hasAlert,
                        },
                    });
                });
            }
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        id: oldShift.id,
                    },
                    to: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                    },
                    item: oldShift,
                    hasAlert: oldShift.hasAlert,
                },
            });
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: result.driverId,
                        week: moment(result.shiftDate).weekday(),
                        id: result.id,
                    },
                    to: {
                        driverId: result.driverId,
                        week: moment(result.shiftDate).weekday(),
                    },
                    item: result,
                    hasAlert: false,
                },
            });
            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },

        onEditRescue(oldShift, rescue) {
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        id: oldShift.id,
                    },
                    to: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                    },
                    item: oldShift,
                    hasAlert: oldShift.hasAlert,
                },
            });

            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: rescue.driverId,
                        week: moment(rescue.shiftDate).weekday(),
                        id: rescue.id,
                    },
                    to: {
                        driverId: rescue.driverId,
                        week: moment(rescue.shiftDate).weekday(),
                    },
                    item: rescue,
                    hasAlert: false,
                },
            });
            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },

        onEditRescueNote(result) {
            let priorShift = cellData.filter((item) => item.routeId === result.priorShiftRouteId);

            const oldShift = {
                ...priorShift[0],
                endTime: result.endTimeOfPriorShift,
                utcEndTime: result.utcEndTimeOfPriorShift,
                hour: `${TimeFormat({
                    time: {
                        timestamp: databaseToMilliseconds(priorShift[0].startTime.toFixed(2).replace(".", ":")) / 1000,
                    },
                })}-${covertUtcToLocal({
                    timestamp:
                        databaseToMilliseconds(result.utcEndTimeOfPriorShift.toFixed(2).replace(".", ":")) / 1000,
                })}`,
            };
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        id: oldShift.id,
                    },
                    to: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                    },
                    item: oldShift,
                    hasAlert: oldShift.hasAlert,
                },
            });
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: result.driverId,
                        week: moment(result.shiftDate).weekday(),
                        id: result.id,
                    },
                    to: {
                        driverId: result.driverId,
                        week: moment(result.shiftDate).weekday(),
                    },
                    item: result,
                    hasAlert: false,
                },
            });
            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },

        onMoveToOpenRoute(oldShift, newShift) {
            dispatch({
                type: "MOVED",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        routeId: oldShift.id,
                    },
                    to: {
                        driverId: newShift.driverId,
                        week: moment(newShift.shiftDate).weekday(),
                    },
                    item: newShift,
                    hasAlert: true,
                },
            });

            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },

        onAssignToAnotherDriver(driver, oldShift, newShift) {
            dispatch({
                type: "MOVED",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        routeId: oldShift.id,
                    },
                    to: {
                        driverId: newShift.driverId,
                        week: moment(newShift.shiftDate).weekday(),
                    },
                    item: newShift,
                    hasAlert: true,
                },
            });

            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },

        onVehicleChange({ description, callback }) {
            callback(state);
        },
        onDeviceChange({ description, callback }) {
            callback(state);
        },

        onRouteChange(shift, newShift) {
            if (shift && shift.olsShiftIsOpen == true) {
                var driver = (state.filter.length ? state.filter : state.state).find((d) => d.id == "0.0.0");
                let oldShift = driver[`w${moment(shift.date).utc().weekday()}`].find((s) => s.routeId == shift.routeId);
                let tempRouteCodes = oldShift.routeCode;
                if (shift.codeCount == 1) {
                    if (oldShift) {
                        actions.onRemoveRoute(oldShift);
                    }
                } else {
                    const newShift = oldShift;
                    newShift.routeCode = tempRouteCodes.filter((x) => x.toString() != shift.code.toString());
                    dispatch({
                        type: "UPDATE",
                        payload: {
                            from: {
                                driverId: oldShift.driverId,
                                week: moment(oldShift.shiftDate).weekday(),
                                id: oldShift.id,
                            },
                            to: {
                                driverId: "0.0.0",
                                week: moment(newShift.shiftDate).weekday(),
                            },
                            item: newShift,
                            hasAlert: true,
                            addChange: true,
                        },
                    });
                }
            } else if (shift && shift.hasRouteCodeChnage == true) {
                var driver = (state.filter.length ? state.filter : state.state).find((d) => d.id == shift.driverId);

                let oldShift = driver[`w${moment(shift.date).weekday()}`].find((s) => s.id == shift.routeId);
                oldShift.routeCode = oldShift.routeCode.filter((x) => x.toString() != shift.code.toString());

                dispatch({
                    type: "UPDATE",
                    payload: {
                        from: {
                            ...oldShift,
                            week: moment(oldShift.shiftDate).weekday(),
                        },
                        to: {
                            ...shift,
                            week: moment(oldShift.shiftDate).weekday(),
                        },
                        item: oldShift,
                        hasAlert: oldShift.hasAlert,
                    },
                });
            } else {
                var driver = (state.filter.length ? state.filter : state.state).find((d) => d.id == shift.driverId);
                let oldShift = driver[`w${moment(shift.shiftDate).weekday()}`].find(
                    (s) => s.id == shift.routeId || s.routeId == shift.routeId
                );
                shift.id = oldShift.id;

                dispatch({
                    type: "UPDATE",
                    payload: {
                        from: {
                            ...shift,
                            week: moment(shift.shiftDate).weekday(),
                        },
                        to: {
                            ...shift,
                            week: moment(shift.shiftDate).weekday(),
                        },
                        item: shift,
                        hasAlert: shift.hasAlert,
                    },
                });
            }

            if (newShift)
                dispatch({
                    type: "UPDATE",
                    payload: {
                        from: {
                            ...newShift,
                            week: moment(newShift.shiftDate).weekday(),
                        },
                        to: {
                            ...newShift,
                            week: moment(newShift.shiftDate).weekday(),
                        },
                        item: newShift,
                        hasAlert: newShift.hasAlert,
                    },
                });
        },
        onIncidentShift(shift, incident) {
            if (shift.hasCallOutOrNcns) {
                setUpdatedScheduler(updateScheduler + 1);
            }
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: shift.driverId,
                        week: moment(shift.shiftDate).weekday(),
                        id: shift.id,
                    },
                    to: {
                        driverId: shift.driverId,
                        week: moment(shift.shiftDate).weekday(),
                    },
                    item: shift,
                    hasAlert: true,
                },
            });
        },
        onRemoveRoute(oldShift) {
            let newShift = {
                ...oldShift,
                deleted: true,
                deletedBy: getFriendlyName(),
                deletedAt: moment().format("MM/DD/YYYY") + " at " + moment().format("h:mm a"),
            };

            if ((oldShift.hasAlert && oldShift.hasAlert == true) || oldShift.driverId == "0.0.0") {
                dispatch({
                    type: "REMOVED",
                    payload: {
                        from: {
                            driverId: oldShift.driver.id,
                            week: moment(oldShift.shiftDate).weekday(),
                            id: oldShift.id,
                        },
                        item: {
                            hasAlert: false,
                        },
                    },
                });
            } else {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        from: {
                            driverId: oldShift.driverId,
                            week: moment(oldShift.shiftDate).weekday(),
                            id: oldShift.id,
                        },
                        to: {
                            driverId: newShift.driverId,
                            week: moment(newShift.shiftDate).weekday(),
                        },
                        item: newShift,
                        hasAlert: false,
                        addChange: false,
                    },
                });
            }
            toast({
                type: "info",
                title: "Remove changes…",
                content: "You can continue your work while we remove your selected changes.",
            });
            if (setUpdatedScheduler) {
                setUpdatedScheduler(updateScheduler + 1);
            }
            // dispatch({
            //     type: "REMOVED",
            //     payload: {
            //         from: {
            //             driverId: shift.driver.id,
            //             week: moment(shift.shiftDate).weekday(),
            //             id: shift.id
            //         },
            //         item: {
            //             hasAlert: false
            //         }
            //     }
            // });
            dispatch({ type: "REMOVE_CHANGES", id: oldShift.id, station: oldShift.stationId });
        },
        onRestoreShift(oldShift, newShift) {
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        id: oldShift.id,
                    },
                    to: {
                        driverId: newShift.driverId,
                        week: moment(newShift.shiftDate).weekday(),
                    },
                    item: newShift,
                    hasAlert: false,
                    addChange: false,
                },
            });
            toast({
                type: "info",
                title: "Restore changes…",
                content: "You can continue your work while we restore your selected changes.",
            });
            setUpdatedScheduler(updateScheduler + 1);
        },
        moveToOpenRoute(oldShift, newShift) {
            newShift.driverId = "0.0.0";
            newShift.isOpenShift = true;
            dispatch({
                type: "MOVED",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        routeId: oldShift.id,
                    },
                    to: {
                        driverId: newShift.driverId,
                        week: moment(newShift.shiftDate).weekday(),
                    },
                    item: newShift,
                    hasAlert: false,
                },
            });

            actions.onRemoveRoute(oldShift);

            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },
        onSendHome(shift) {
            dispatch({ type: "UPDATE_SINGLE_SHIFT", payload: { weekNum: dataKey, weekIdx: dataKey, shift: shift } });
            setUpdatedScheduler(updateScheduler + 1);
        },
        onUnSendHome(shift) {
            dispatch({ type: "UPDATE_SINGLE_SHIFT", payload: { weekNum: dataKey, weekIdx: dataKey, shift: shift } });
            setUpdatedScheduler(updateScheduler + 1);
        },
        onAddRefuseRescue(shift) {
            shift.isRefusedRescue = true;
            dispatch({ type: "UPDATE_SINGLE_SHIFT", payload: { weekNum: dataKey, weekIdx: dataKey, shift: shift } });
        },
        onEditNormalShift: (oldShift, newShift) => {
            dispatch({
                type: "UPDATE",
                payload: {
                    from: {
                        driverId: oldShift.driverId,
                        week: moment(oldShift.shiftDate).weekday(),
                        id: oldShift.id,
                    },
                    to: {
                        driverId: newShift.driverId,
                        week: moment(newShift.shiftDate).weekday(),
                    },
                    item: newShift,
                    hasAlert: false,
                    addChange: false,
                },
            });

            if (tableRef && tableRef.current) {
                tableRef.current.recomputeRowHeights();
            }
        },
        onRemoveDriverVacationOrTerminationShift: () => {
            setUpdatedScheduler(updateScheduler + 1);
        },
    };

    const driverData = {
        id: rowData.id,
        name: rowData.driver,
        img: rowData.profile,
        workHours: rowData.workHours,
        estimatedWorkHours: rowData.estimatedWorkHours,
        skills: rowData.skills,
        hasAlert: rowData.alert,
        stationId: rowData.stationId,
        employeeStatus: rowData.employeeStatus,
        inactiveDate: rowData.inactiveDate,
        terminationDate: rowData.terminationDate,
        offboardedDate: rowData.offboardedDate,
    };

    let data = [];
    let date = state?.schedulerFooterData[columnIndex]?.date;
    if (cellData) {
        let incidentTypes = {};

        if (state && state.incidentTypes) {
            incidentTypes = state.incidentTypes;
        }
        if (cellData && cellData.length == 0) {
            if (date && rowData?.prefferStatus[date]) {
                if (rowData?.prefferStatus[date] == 2) {
                    data.push(<svg style={{ position: "absolute", top: 0, right: 0 }} width="10px" height="10px" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Vector" d="M8.871 0.003C7.704 0.003 6.75 0.585 6.129 1.491C6.066 1.584 5.931 1.584 5.868 1.491C5.25 0.582 4.293 0.003 3.126 0.003C1.542 0.003 0 1.395 0 3.282C0 5.364 1.527 7.131 2.94 8.334C3.753 9.021 4.632 9.633 5.559 10.155C5.643 10.203 5.727 10.248 5.814 10.293C5.874 10.323 5.937 10.338 6 10.338C6.063 10.338 6.129 10.323 6.186 10.293C6.27 10.248 6.354 10.203 6.438 10.155C6.597 10.065 6.819 9.936 7.089 9.768C7.779 9.336 8.436 8.856 9.06 8.331C10.473 7.131 12 5.361 12 3.279C12 1.392 10.455 0 8.871 0V0.003Z" fill={"#00A38D"} />
                    </svg>);
                } else if (rowData?.prefferStatus[date] == 3) {
                    data.push(<svg style={{ position: "absolute", top: 0, right: 0 }} width="10px" height="10px" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M-618-1464H782v3600H-618zM0 0h24v24H0z"></path><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM4 12c0-4.4 3.6-8 8-8 1.8 0 3.5.6 4.9 1.7L5.7 16.9C4.6 15.5 4 13.8 4 12zm8 8c-1.8 0-3.5-.6-4.9-1.7L18.3 7.1C19.4 8.5 20 10.2 20 12c0 4.4-3.6 8-8 8z"></path></svg>);
                }
            }
        }

        cellData.map((item, key) => {
            item.driver = driverData;
            item.stateKey = [rowData.id, dataKey, key];
            if (item.deleted || item.isGhostMode) {
                data.push(
                    <div
                        id={item.id}
                        style={{ position: "relative", display: "block", padding: openShiftOverflow ? "8px 0 " : 0 }}
                    >
                        <ShiftBlock
                            type={item.type}
                            hour={item.hour}
                            background={item.bg}
                            schedule={item.sheduleName}
                            color={item.color}
                            hasAlert={item.isNew == true ? "true" : false}
                            hasIncident={item.incident ? item.incident.type : false}
                            // containerClass='ReactVirtualized__Grid__innerScrollContainer'
                            sentToHome={item.isSentHome}
                            refuseRescue={item.isRefusedRescue}
                            hasCallOutOrNcns={item.hasCallOutOrNcns}
                            isTimeOff={item.isTimeOff}
                            hasAnySinglePunch={item.hasAnySinglePunch}
                            isDeleted={item.deleted}
                            isGhostMode={item.isGhostMode}
                            ghostStation={item.ghostStation}
                            isRestore={item.isRestore}
                            category={item.category}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            prefferStatus={rowData?.prefferStatus ? (rowData?.prefferStatus[item.dateCreated] ? rowData?.prefferStatus[item.dateCreated] : null) : null}
                            handleDelete={handleDelete}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            handleOpenDelete={handleOpenDelete}
                        >
                            {({ open, setOpen }) => (
                                <ShiftBlockPopup
                                    shift={item}
                                    open={{ open, setOpen }}
                                    actions={actions}
                                    schedule={item.sheduleName}
                                    incidents={incidentTypes}
                                    viewType="week"
                                    isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                />
                            )}
                        </ShiftBlock>
                    </div>
                );
            } else if (['UNAVAILABLE','SUSPENDED'].includes(item.type)) {
                data.push(
                    <div
                        id={item.id}
                        style={{ position: "relative", display: "block", padding: openShiftOverflow ? "8px 0 " : 0 }}
                    >
                        <ShiftBlock
                            type={item.type}
                            hour={item.hour}
                            background={item.bg}
                            schedule={item.sheduleName}
                            color={item.color}
                            hasAlert={item.isNew == true ? "true" : false}
                            hasIncident={item.incident ? item.incident.type : false}
                            sentToHome={item.isSentHome}
                            refuseRescue={item.isRefusedRescue}
                            hasCallOutOrNcns={item.hasCallOutOrNcns}
                            isTimeOff={item.isTimeOff}
                            hasAnySinglePunch={item.hasAnySinglePunch}
                            category={item.category}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            prefferStatus={rowData?.prefferStatus ? (rowData?.prefferStatus[item.dateCreated] ? rowData?.prefferStatus[item.dateCreated] : null) : null}
                            handleDelete={handleDelete}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            handleOpenDelete={handleOpenDelete}
                        >
                            {({ open, setOpen }) => (
                                <ShiftBlockPopup
                                    shift={item}
                                    open={{ open, setOpen }}
                                    actions={actions}
                                    schedule={item.sheduleName}
                                    incidents={incidentTypes}
                                    viewType="week"
                                    isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                />
                            )}
                        </ShiftBlock>
                    </div>
                );
            } else {
                data.push(
                    <ShiftDraggable
                        key={key}
                        tableRef={tableRef}
                        setPublishkey={key}
                        item={item}
                        stateKey={[rowData.id, dataKey]}
                        driver={driverData}
                        openShiftOverflow={openShiftOverflow}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                    >
                        <ShiftBlock
                            type={item.type}
                            hour={item.hour}
                            background={item.bg}
                            schedule={item.sheduleName}
                            color={item.color}
                            hasAlert={item.isNew == true ? "true" : false}
                            hasIncident={item.incident ? item.incident.type : false}
                            // containerClass='ReactVirtualized__Grid__innerScrollContainer'
                            sentToHome={item.isSentHome}
                            refuseRescue={item.isRefusedRescue}
                            hasCallOutOrNcns={item.hasCallOutOrNcns}
                            isTimeOff={item.isTimeOff}
                            hasAnySinglePunch={item.hasAnySinglePunch}
                            category={item.category}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            // shift={shift}
                            driver={item}
                            prefferStatus={rowData?.prefferStatus ? (rowData?.prefferStatus[item.dateCreated] ? rowData?.prefferStatus[item.dateCreated] : null) : null}
                            handleDelete={handleDelete}
                            menuVisible={menuVisible}
                            setMenuVisible={setMenuVisible}
                            handleOpenDelete={handleOpenDelete}
                        >
                            {({ open, setOpen }) => (
                                <ShiftBlockPopup
                                    shift={item}
                                    open={{ open, setOpen }}
                                    actions={actions}
                                    schedule={item.sheduleName}
                                    incidents={incidentTypes}
                                    viewType="week"
                                    isUserAccessAuthoriseCompleteIncident={state?.isUserAccessAuthoriseIncident ?? false}
                                />
                            )}
                        </ShiftBlock>
                    </ShiftDraggable>
                );
            }
        });
    } else {
        cellData = [];
    }

    const upperStyle = {
        visibility: "visible"
    };
    if (date && rowData?.prefferStatus[date]) {
        if ((rowData?.prefferStatus[date] == 1 || rowData?.prefferStatus[date] == 2))
            upperStyle.background = "#E5F8F6";
        else if ((rowData?.prefferStatus[date] == 3))
            upperStyle.background = "#f0f0f0";
    }

    return (
        <ShiftDroppable
            tableRef={tableRef}
            data={{ accept: rowData.skills }}
            stateKey={[rowData.id, dataKey]}
            driver={driverData}
            shiftsFromThisWeek={rowData[dataKey]}
            openShiftOverflow={openShiftOverflow}
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: cellData.length > 1 ? "space-between" : "center",
                position: "relative",
            }}
            upperStyle={upperStyle}
            isBoxEmpty={data.length === 0}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
        >
            {data}
        </ShiftDroppable>
    );
};

const UnshiftWeekRender = ({ shifts, rowData, dataKey, dispatch, selectedRows, setSelectedRows }) => {
    const groups = shifts.reduce((newData, data) => {
        const key = `${data.typeId}.${data.startTime}.${data.endTime}`;

        if (!newData[key]) {
            data.qty_group = 1;
            newData[key] = data;
        } else {
            newData[key].qty_group += 1;
        }
        return newData;
    }, []);

    const onClickHandler = () => {
        dispatch({ type: "EXPAND_OPEN_SHIFTS" });
    };

    return (
        <>
            {Object.values(groups).map((item, key) => {
                item.driver = {
                    id: rowData.id,
                    stationId: rowData.stationId,
                };
                item.stateKey = [rowData.id, dataKey, key];
                return (
                    <ShiftBlock
                        key={key}
                        type={item.type}
                        hour={item.hour}
                        background={item.bg}
                        color={item.color}
                        schedule={item.sheduleName}
                        hasAlert={item.qty_group > 1 ? item.qty_group : false}
                        onClickHandler={onClickHandler}
                        category={item.category}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                    ></ShiftBlock>
                );
            })}
        </>
    );
};

const ShiftDraggable = ({ item, stateKey, children, driver, openShiftOverflow, tableRef, shiftsFromThisWeek, selectedRows, setSelectedRows }) => {
    const [state, dispatch] = useContext(WeekStore);
    const { api } = useAuth();
    let [currDate] = FilterWeekDate();
    const userId = localStorage.getItem('userId');
    const { multiEditShift } = useSchedulerApi();

    const [{ didDrop, isDragging }, drag] = useDrag({
        item: { item, type: "shift", stateKey, driver },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            didDrop: monitor.subscribeToStateChange,
        }),
        canDrag: selectedRows.length === 0,
        end: async (item, monitor) => {
            if (!dropSource) {
                return alert(`Please drop on desired block`);
            }
            const hasMultipleDriverErrorMsg = () => {
                const firstDriverId = selectedRows[0]?.driverId;
                for (let i = 1; i < selectedRows.length; i++) {
                    if (selectedRows[i].driverId !== firstDriverId) {
                        return true;
                    }
                }
            }
            if (hasMultipleDriverErrorMsg()) {
                return alert(`You cannot move/copy multiple drivers`);
            }
            const selectedDriver = state?.filter.find((driver) => driver.id === dropSource[0]);
            const selectedRowLength = selectedRows?.length;
            const dropTarget = dropSource[1];
            const dropTargetIndex = parseInt(dropTarget.substring(1));
            const dropTargets = Array.from({ length: selectedRowLength }, (_, i) => "w" + (dropTargetIndex + i) % 7);
            function checkDropTargets(selectedDriver, dropTargets) {
                for (const target of dropTargets) {
                    const last = dropTargets.length > 1 && dropTargets?.slice(-1);
                    if (last[0] === "w0") return true;
                    if (selectedDriver[0][target]?.length > 0) {
                        return true;
                    }
                }
                return false;
            }

            const hasError = checkDropTargets([selectedDriver], dropTargets);

            if (hasError) {
                return alert(`You cannot merge to the existing shift`);
            }

            await dragMoveCopy(item, monitor, tableRef, shiftsFromThisWeek, stateKey, dropSource,
                selectedRows, currDate, userId, state, dispatch, api, selectedDriver, setSelectedRows, multiEditShift);
            dropSource = null;
        }
    });

    return (
        <div
            ref={drag}
            style={{ display: isDragging ? "none" : "block", padding: openShiftOverflow ? "8px 0 " : 0 }}
            data-id={JSON.stringify(item.id)}
            data-route-id={JSON.stringify(item.routeId)}
            data-driver-id={JSON.stringify(item.driverId)}
        >
            {children}
        </div>
    );
};

const ShiftDroppableStyle = styled.div`
    width: 100%;
    height: 100%;
    padding: 8px;

    &.pastday {
        background: ${Theme.colors.neutrals.silver}1f;
    }

    &.today {
        background: ${Theme.colors.primary}10;
    }

    &.today,
    &.futureday {
        cursor: pointer;

        &:hover {
            background: ${Theme.colors.primary}2f;
        }
    }
`;

const ShiftDroppable = ({
    data,
    stateKey,
    style,
    children,
    tableRef,
    driver,
    shiftsFromThisWeek,
    openShiftOverflow,
    upperStyle,
    selectedRows = [],
    isBoxEmpty,
    setSelectedRows
}) => {
    const [state, dispatch] = useContext(WeekStore);
    const { api } = useAuth();
    const schedulerApi = useSchedulerApi();
    const { handleGhostShift } = useGhostShiftApi();
    let [currDate] = FilterWeekDate();
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: "shift",
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: selectedRows?.length === 0 ? monitor.canDrop() : false,
            didDrop: monitor.didDrop(),
        }),
        drop: async (item, monitor) => {
            dropSource = stateKey;
        },
    });

    let day = stateKey[1][1];
    let week = moment(currDate).isoWeek();
    let today = moment().format("YYYY-MM-DD");
    let thisDay = moment(currDate).isoWeek(week).day(day).format("YYYY-MM-DD");
    let dayClass = today == thisDay ? "today" : today > thisDay ? "pastday" : "futureday";
    const popUpRef = useRef()

    const handleAdd = async (e) => {
        if (localStorage.getItem('role') == 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE' && driver.id == '0.0.0') {
            return alert("You don't have permission.");
        }
        let dayBox = await state.state.filter((obj) => obj.id == driver.id)[0]["w" + stateKey[1][1]];
        if (dayBox[0]) {
            let returnOrNot = false;
            await dayBox.map((s) => {
                if (s.category == 8 || s.category == 9 || s.category == 10) {
                    returnOrNot = true;
                }
            });
            if (returnOrNot) {
                return false;
            }
        }
        let now = moment(currDate);
        let day = stateKey[1][1];
        let week = moment(currDate, "YYYY-MM-DD").isoWeek();
        let normalWeek = moment(currDate, "YYYY-MM-DD").week();
        let date = now.week(normalWeek).day(day);
        if (
            driver.employeeStatus == false &&
            (driver.inactiveDate < date.format("YYYY-MM-DD") ||
                driver.terminationDate < date.format("YYYY-MM-DD") ||
                driver.offboardedDate < date.format("YYYY-MM-DD"))
        ) {
            return alert("You cannot add shifts because of this driver is not active!");
        }
        if (driver.id != "0.0.0") {
            // handle ghost shift
            const getResponse = await handleGhostShift({
                stationId: JSON.parse(localStorage.getItem("selected")),
                driverId: driver.id,
                date: date.format("YYYY-MM-DD"),
            });
            if (!getResponse) return false;
            const params1 = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "checkSequenceDriverRoute",
                                get: "checkSequenceDriverRoute",
                                excludes: [],
                                criteria: {
                                    routeId: "",
                                    driverId: driver.id,
                                    newDate: date.format("YYYY-MM-DD"),
                                },
                            },
                        },
                    },
                },
            };
            const response = await api.post("/api/lazy/manage/data", params1);
            if (response.data.data.checkSequenceDriverRoute.status) {
                const wc = await confirm(response.data.data.checkSequenceDriverRoute.message.web);
                if (!wc) return undefined;
                const params1 = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "createEventForConsicutiveShift",
                                    get: "createEventForConsicutiveShift",
                                    excludes: [],
                                    criteria: {
                                        driverId: driver.id,
                                        message: response.data.data.checkSequenceDriverRoute.message.bot,
                                    },
                                },
                            },
                        },
                    },
                };
                const response1 = await api.post("/api/lazy/manage/data", params1);
            }
        }
        const paramsStationObject = {
            "actions": {
                "response": {
                    "Station": {
                        "findOneBy": {
                            "criteria": {
                                "id": state.selectedStation
                            },
                            "get": "Station",
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "stationAddress",
                                "3": "stationGeofence",
                                "4": "parkingLotAddress",
                                "5": "parkingLotGeofence",
                                "6": "code",
                                "7": "timezone"
                            },
                            "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
                        }
                    }
                }
            }
        }
        let responseStationObj = await api.post('/api/lazy/manage/data', paramsStationObject);
        let timeZoneShortName = null;
        if (responseStationObj.data.data.Station) {
            let currentSelectedStationObj = responseStationObj.data.data.Station;
            timeZoneShortName = currentSelectedStationObj.timezone;
        }
        showFlexibleShiftBlockForm({
            api: schedulerApi,
            title: "Add Shift",
            driver: driver,
            date: date,
            timeZoneShortName: timeZoneShortName,
            currentPopRef: popUpRef.current,
        }).then(async (shift) => {
            if (!shift) return;
            if (driver.id != "0.0.0") {
                const driverSkillParam = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "checkDriverAndShiftSkillMatch",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        driverId: driver.id,
                                        shiftId: shift.shiftType,
                                    },
                                },
                            },
                        },
                    },
                };
                const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                if (!responseDriverSkill.data.data.result.result) {
                    if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                        alert({
                            text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                            btnText: "Cancel",
                        });
                        return false;
                    } else {
                        const confirmation = await confirm(
                            'WARNING: "' +
                            responseDriverSkill.data.data.result.driverName +
                            '" does not have the required "' +
                            responseDriverSkill.data.data.result.shiftSkillName +
                            '" skill to be assigned this shift type. Are you sure you want to continue?'
                        );
                        if (!confirmation) {
                            return true;
                        }
                    }
                }
            }
            let params = {
                actions: {
                    response: {
                        TempDriverRoute: {
                            custom: {
                                functionName: "addTempDriverRoute",
                                get: "tempDriverRoute",
                                excludes: [],
                                criteria: {
                                    timezoneOffset: new Date().getTimezoneOffset(),
                                    isNew: true,
                                    isTemp: true,
                                    newDriverId: driver.id || null,
                                    newDate: date.format("YYYY-MM-DD"),
                                    isOpenShift: driver.id ? false : true,
                                    shiftId: shift.shiftType,
                                    routeId: "",
                                    weekTo: date.weekday(),
                                    shiftInvoiceType: shift.shiftInvoiceType,
                                    startTime: shift.startTime,
                                    endTime: shift.endTime,
                                    routeStatus: shift.routeStatus,
                                    isVoluntary: shift.isVoluntary,
                                    autoApproveDriverRequest: shift.autoApproveDriverRequest,
                                    manualApproveDriverRequest: shift.manualApproveDriverRequest,
                                    item: {
                                        ...shift,
                                        type: shift.extra.name,
                                        hour:
                                            TimeFormat({ time: shift.extra.startTime }) +
                                            "–" +
                                            TimeFormat({ time: shift.extra.endTime }),
                                        bg: shift.extra.color,
                                        color: shift.extra.textColor,
                                        isNew: true,
                                        //note: "–",
                                        time: "00:00",
                                        stationId: driver.id ? driver.stationId : false,
                                    },
                                    hasAlert: true,
                                },
                            },
                        },
                    },
                },
            };
            api.post("/api/lazy/manage/data", params).then((response) => {
                dispatch({
                    type: "ADDED",
                    payload: {
                        to: {
                            driverId: driver.id || "0.0.0",
                            week: date.weekday(),
                        },
                        item: response.data.data.tempDriverRoute,
                        hasAlert: true,
                    },
                });
                toast({
                    type: "success",
                    title: "Shift Created",
                    content: "You must publish your changes.",
                });
                tableRef.current.recomputeRowHeights();
            });
        });
        
    };

    const past = dayClass === "pastday" && canModifyPast();
    const future = dayClass === "futureday" && canModifyFuture();

    // remove add shift hover effect
    if (dayClass === "futureday" && !future) dayClass = "";

    const now = moment(currDate);
    const normalWeek = moment(currDate, "YYYY-MM-DD").week();
    let date = now.week(normalWeek).day(day);
    date = date.format("YYYY-MM-DD");

    const handleSelection = () => {
        setSelectedRows((prevSelectedRows) => {
            const isSelected = prevSelectedRows.findIndex((row) => row.date === date && row.id === driver.id);
            if (isSelected >= 0) {
                const tempRows = [...prevSelectedRows]
                tempRows.splice(isSelected, 1);
                if (tempRows.length === 0) {
                    dispatch({
                        type: "EMPTY_BOX_SELECT",
                        payload: false,
                    });
                }
                return tempRows;
            } else {
                return [...prevSelectedRows, { ...driver, date, stateKey }];
            }
        });
    }

    const handleEmptyBoxClick = async (evt) => {
        if (evt.shiftKey) {
            const isBoxNotSelectable = await isEmptyWeekBoxNonSelectable(state, stateKey, driver, currDate)
            if (isBoxNotSelectable) {
                return;
            } else {
                if (isBoxEmpty) {
                    dispatch({
                        type: "EMPTY_BOX_SELECT",
                        payload: true,
                    });
                    handleSelection()
                }
            }
        } else if (dayClass === "today" || past || future) {
            setSelectedRows([]);
            dispatch({
                type: "EMPTY_BOX_SELECT",
                payload: false,
            });
            handleAdd(evt);
        }

    }
    const isEmptySelection = selectedRows.length > 0 && !isBoxEmpty && state.isEmptyBoxSelected;
    const isNonEmptySelection = selectedRows.length > 0 && isBoxEmpty && !state.isEmptyBoxSelected;
    const borderEmptyBoxSelected = selectedRows.findIndex(row => row.date === date && row.id === driver.id) >= 0;

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "block",
                overflow: openShiftOverflow ? "scroll" : "visible",
                opacity: (isEmptySelection || isNonEmptySelection) ? '0.5' : '1',
                pointerEvents: (isEmptySelection || isNonEmptySelection) ? 'none' : '',
                border: borderEmptyBoxSelected ? '4px solid rgba(189, 55, 164, 0.5)' : ''
            }}
        >
            <ShiftDroppableStyle
                onClick={handleEmptyBoxClick}
                className={dayClass}
                style={upperStyle}
                ref={popUpRef}
            >
                <div ref={drop} style={style}>
                    {children}
                </div>
            </ShiftDroppableStyle>
        </div>
    );
};

const Footer = ({ data, height, width, firstColWidth, totalDrivers, colWidth, tableRef }) => {
    const [state, dispatch] = useContext(WeekStore);
    useEffect(() => {
        if (tableRef && !state.refTable) {
            dispatch({ type: "REF", payload: tableRef });
        }
    }, [tableRef]);

    let DriverInfo = data[0];

    return (
        <FooterContainer className="table-footer" style={{ display: "block", height: height, width: width - 2 }}>
            <FooterColumn
                width={firstColWidth}
                cstyle={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div>
                    <span className="text blue">&nbsp;&nbsp;{DriverInfo && DriverInfo.totalRoutes}R</span>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Icon.Person size="16px" color="#707070" />
                    <Spacer left={1}>
                        <span className="text">{totalDrivers}</span>
                    </Spacer>

                    <Spacer left={3}>
                        <Icon.ArrowDown size="16px" color="#7C98B6" style={{ marginTop: "4px" }} />
                    </Spacer>
                </div>
            </FooterColumn>

            {data.map((item, index) => {
                let driverCount = "";
                if (item.date <= moment().format("YYYY-MM-DD")) driverCount = item.routeCodes + "R /";
                return index > 0 ? (
                    <Fragment key={index}>
                        <FooterColumn
                            width={index == 7 ? colWidth - 3 : colWidth}
                            height={height}
                            groups={item.groups}
                            colPosition={index}
                        >
                            <FooterWeek>
                                <span className="text">
                                    <span style={{ color: item.grading ? item.grading.color : "" }}>
                                        {item.DriversWithRoute}D / {driverCount} {item.PlanRoutes}RC
                                    </span>
                                </span>
                            </FooterWeek>
                        </FooterColumn>
                    </Fragment>
                ) : null;
            })}
        </FooterContainer>
    );
};

const FooterColumn = ({ width, height, colPosition, groups, children, cstyle }) => {
    let popupPosition = { left: width - 32, bottom: height - 8 };
    if (colPosition == 7 || colPosition == 6) {
        popupPosition = { right: -4, bottom: height - 8 };
    }
    let maxHeight = window.innerHeight - 110;
    return (
        <FooterCol maxHeight={maxHeight} style={{ width: width }}>
            <FooterColumnComponent
                component={children}
                groups={groups}
                colPosition={colPosition}
                popupPosition={popupPosition}
                cstyle={cstyle}
            />
        </FooterCol>
    );
};

export const NoteEditDailog = ({ success, cancel, dispatch, title, driver, note }) => {
    const { register, handleSubmit, errors, reset, getValues } = useForm({ notes: note });
    const [loading, setLoading] = useState(false);

    const editNote = async (data) => {
        const params1 = {
            actions: {
                update: {
                    Driver: {
                        update_driver: {
                            findBy: {
                                id: driver,
                            },
                            updateRecord: {
                                drivingPreference: data.notes
                            }
                        }
                    }
                }
            }
        };
        const response = await engine().post("/api/lazy/manage/data", params1);
        if (response) {
            dispatch({
                type: "SET_NOTES",
                payload: {
                    driver: driver,
                    notes: data.notes,
                },
            });
            success(true);
            toast({
                type: "success",
                title: "Note successfully changed.",
                timeout: 8000,
                useIcon: true,
                useClose: true,
            });
        } else {
            success(false);
            toast({
                type: "error",
                title: "Error in password reset !!!",
                timeout: 8000,
                useIcon: true,
                useClose: true,
            });
        }
    };

    return (
        <Modal width="340px" title={title || ""} visible={true} setVisible={cancel} disabled={loading}>
            {loading ? (
                <div style={{ textAlign: "center" }}>
                    <Loading style={{ width: "40px" }} />
                </div>
            ) : (
                <form name="edit_note" onSubmit={handleSubmit(editNote)}>
                    <Input
                        name='notes'
                        label="Note"
                        size='big'
                        style={{ height: 76, width: 300 }}
                        as='textarea'
                        defaultValue={note}
                        ref={register}
                    />
                    <Spacer top={5} style={{ textAlign: "right" }}>
                        <Button type="primary">Save</Button>
                    </Spacer>
                </form>
            )}
        </Modal>
    );
};

export default TableApp;
