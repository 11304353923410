const weekReducer = (state,action) => {
    const {type, payload} = action;

    const addChange = (station,ids) => {
        let stationId = station ? station : state.selectedStation;

        state.changes[stationId] = state.changes[ stationId] || [];
        ids.forEach(function(id) {
            if(state.changes[stationId].length > 0) {
                if (!state.changes[stationId].includes(id.toString())) {
                    state.changes[stationId].push(id.toString());
                }
            } else {
                state.changes[stationId].push(id.toString());
            }
        });
    }

    switch (type) {
        case 'ADD':
            const initialFilter = {
                unscheduled: false,
                stations: Array.isArray(state.selectedStation) ? state.selectedStation.map(s => s.toString()) : state.selectedStation
            };

            let orderValue = JSON.parse(localStorage.getItem('dayOrderValue'));
            if (typeof orderValue == "undefined" || orderValue == null) {
                localStorage.setItem('dayOrderValue', JSON.stringify({ "mainFields": [] }));
                orderValue = JSON.parse(localStorage.getItem('dayOrderValue'));
            }
            let f = Filter(payload.data.driversShifts, state.initialFilter);
            let orderedData = f;
            orderedData = order(f, orderValue);

            const fo = FilterTop(payload.data.openShifts, state.initialFilter, 'open');
            const fb = FilterTop(payload.data.backupDrivers,state.initialFilter,'backup');
            return {
                ...state,
                data:payload.data,
                sidebarFilter:payload.filter,
                filter: orderedData,
                openFiltered:fo,
                backupFiltered:fb,
                isLoading:false,
                hasFilter: true
            }
        case 'REF':
            return {...state,refTable:payload}
        case 'INITIAL_FILTER':
            return {...state,initialFilter:payload};
        case 'MOVE':
            const {toObj,fromObj} = solveArray(state.data,payload.to.type,payload.from.type,payload);
            moveShift(toObj,fromObj,state.data);

            state.filter = Filter(state.data.driversShifts,state.initialFilter)
            state.openFiltered = FilterTop(state.data.openShifts,state.initialFilter,'open')
            state.backupFiltered = FilterTop(state.data.backupDrivers,state.initialFilter,'open')
            while (payload.shift.startTime < state.data.hours[0].hour) {
                let h = state.data.hours[0].hour - 1;
                state.data.hours.unshift({
                    hour: h,
                    text: h > 12
                        ? `${h - 12}pm`
                        : `${h}am`
                })
            }

            while (payload.shift.endTime > state.data.hours[state.data.hours.length-1].hour) {
                let h = state.data.hours[state.data.hours.length-1].hour + 1;
                state.data.hours.push({
                    hour: h,
                    text: h > 12
                        ? `${h - 12}pm`
                        : `${h}am`
                })
            }

            state.lastModified = new Date().getTime();
            if(!payload.isIncident && !payload.isNotCount){
                if(payload.to.type != 'delete'){
                    if(payload.shift.id !=  undefined && payload.shift.isSecondShift == false && payload.shift.isRescuer == false){
                        if (payload.hasAlert)
                            addChange(payload.shift.stationId,[payload.shift.id]);
                    }
                }
            }
            if(payload.isIncident && payload.shift.incident.type != 'Late'){
                const {driverId,startTime} = payload.from;
                const driverKey = state.data.driversShifts.findIndex(el => el.id === driverId)
                const shift = state.data.driversShifts[driverKey][`shift_${startTime}`];
                if(shift){
                    const timelineKey = shift.timeline.findIndex(s => s.type=="Target");
                    shift.timeline.splice(timelineKey,1);
                }
            }
            return {...state}

        case 'UPDATE_ROUTE_CODES':
            if(payload.shift.isBackup){
                actions.backupDriver(state.backupFiltered, 'ADD', payload.shift);
            }else{
                actions.driverShift(state.data.driversShifts, 'ADD', payload.shift);
                if (payload.newShift)
                    actions.driverShift(state.data.driversShifts, 'ADD', payload.newShift);
            }


            return {...state};
        case 'CLEAN_CHANGES':
            if(payload.qty > 0){
                const newV = state.changes[state.selectedStation].filter((d,k)=> k > payload.qty)

                state.changes[state.selectedStation] = newV;
            }else{
                state.changes[state.selectedStation] = [];
            }
            return {...state}

        case 'FILTER':
            
            const filtered = Filter(state.data.driversShifts,payload);
            const openFiltered = FilterTop(state.data.openShifts,payload,'open');
            const backupFiltered = FilterTop(state.data.backupDrivers,payload,'backup');
            let hasFilter = true;
            //state.changes++;

            let newState = {...state};
            if(filtered){
                newState.filter = filtered;
                newState.hasFilter = hasFilter;
            }
            if(openFiltered) newState.openFiltered = openFiltered;

            if(backupFiltered) newState.backupFiltered = backupFiltered;
            
            const orderValue1 = JSON.parse(localStorage.getItem('dayOrderValue'));

            if (typeof orderValue1 == "undefined" || orderValue1 == null) {
                localStorage.setItem('dayOrderValue', JSON.stringify({ "mainFields": [] }));
            }
            newState.filter = order(filtered, orderValue1);

            newState.initialFilter = payload;

            return newState;
            //return {...state,hasFilter:hasFilter};
        case 'ORDER':
            const ordered = order(state.data.driversShifts, payload);
            localStorage.setItem('dayOrderValue', JSON.stringify(payload));
            if (state.filter.length > 0) {
                const ordered_filter = order(state.filter, payload);
                return { ...state, state: ordered, filter: ordered_filter};
            }
            return {...state,state:ordered};
        case 'EXPAND_OPEN_SHIFTS':
            state.openShifts = !state.openShifts;
            return {...state}
        case 'EXPAND_DRIVER_SHIFTS':
            state.driverShifts = !state.driverShifts;
            return {...state}
        case 'ADD_SHIFT_EVENT':
            const {driverId,startTime,event} = payload;
            const driverKey = state.data.driversShifts.findIndex(el => el.id === driverId)
            const shift = state.data.driversShifts[driverKey][`shift_${startTime}`];
            if(shift){
                shift.timeline.push(event);
            }
            return {...state};
        case 'LOADING':
            return {...state,isLoading: true}

        case "UPDATE_SINGLE_SHIFT":
            const driverCurrIndex = state.data[payload.type].findIndex((v)=> payload.shift.driverId === v.driverId);
            const shiftTime = payload.shift.startTime;
            if(driverCurrIndex >= 0){
                state.data[payload.type][driverCurrIndex][`shift_${shiftTime}`] = payload.shift;
            }

            const filterDriverCurrIndex = state[payload.filterIndx].findIndex((v)=> payload.shift.driverId === v.driverId);
            if(filterDriverCurrIndex >= 0){
                state[payload.filterIndx][filterDriverCurrIndex][`shift_${shiftTime}`] = payload.shift;
            }
            return {...state}


        case "SET_PUBLISH_COUNT":
            if(action.api)
                state.changes = {};
            addChange(action.station, action.ids);
            return {...state}

        case "REMOVE_CHANGES":
            let ch = state.changes[action.station];
            ch = ch.filter(function(item) {
                return item !== action.id.toString()
            });
            state.changes[action.station] = ch;
            return {...state}


        case "SET_INCIDENT_TYPES":
            return { ...state, incidentTypes: payload };

        case 'REMOVED':
            let OpenshiftArray =  state.data.openShifts;
            let indexSource = state.openFiltered.findIndex( shift => shift.id === payload.id );
            state.openFiltered.splice(indexSource,1);
            return {...state}
        
        case "SET_NOTES":
            const driverCurrIndex1 = state.data.driversShifts.findIndex((v)=> payload.driver === v.id);
            console.log(payload);
            console.log(driverCurrIndex1);
            if(driverCurrIndex1 >= 0){
                state.data.driversShifts[driverCurrIndex1]['notes'] = payload.notes;
            }
            console.log(state.data.driversShifts[driverCurrIndex1]);
            return { ...state};

        case "EMPTY_BOX_SELECT":
            return {...state, isEmptyBoxSelected: payload}
        case "SET_USER_ACCESS_AUTHORISE_INCIDENT":
            return { ...state, isUserAccessAuthoriseIncident: payload }
       /* case "ON_OPEN_ROUTE":
            console.log(state.data.driversShifts);
            console.log(payload.driverId);
            let indexx = state.data.driversShifts.findIndex(el => el.id === payload.driverId);
            console.log(indexx);
            return false;
           // return { ...state, incidentTypes: payload }; */

        default: return state

    }
}

const solveArray = (data,toType,fromType,payload) => {
    let toArray=[];
    let toAction;
    let toData;
    let toMethod;
    if(toType === 'OPEN_SHIFT'){
        toArray = data.openShifts;
        toAction ='openShift';
        toData = payload.shift;
        toMethod = payload.to.method
    }
    if(toType === 'DRIVERS_SHIFT'){
        toArray = data.driversShifts;
        toAction ='driverShift';
        toData = {driverId:payload.to.driverId,startTime:payload.shift.startTime,shift:payload.shift};
    }
    /*if(fromType === 'BACKUP_SHIFT'){
        toArray = data.backupDrivers;
        toAction = 'backupDriver';
        toData = {driverId:payload.to.driverId,startTime:payload.shift.startTime,shift:payload.shift};
    }*/

    let fromArray=[];
    let fromAction;
    let fromData;
    if(fromType === 'DRIVERS_SHIFT'){
        fromArray = data.driversShifts;
        fromAction = 'driverShift';
        fromData = {driverId:payload.from.driverId,startTime:payload.from.startTime,shift:payload.shift};
    }
    if(fromType === 'OPEN_SHIFT'){
        fromArray = data.openShifts;
        fromAction = 'openShift';
        fromData = payload.shift;
    }
    if(fromType === 'BACKUP_SHIFT'){
        fromArray = data.backupDrivers;
        fromAction = 'backupDriver';
        fromData = {shift:payload.shift,driverId:payload.from.driverId,startTime:payload.from.startTime};
    }

    return {
        toObj:{array:toArray,action:toAction,data:toData,method:toMethod,payload:payload},
        fromObj:{array:fromArray,action:fromAction,data:fromData,moveOpenToShift:payload.from}
    };
}

const moveShift = (toObj, fromObj, data) => {
    if (toObj.payload.to.method === "copy" || toObj.payload.to.method === "copyMulti") {
        const array = toObj.array;
        const driverIndex = array.findIndex(el => el.id == toObj.payload.to.driverId);
        const shift = toObj.payload.to.method === "copy" ? [toObj.payload.shift] : toObj.payload.shift;
        for (let i = 0; i < shift.length; i++) {
            const shiftKey = `shift_${shift[i].startTime.toFixed(2)}`;
            array[driverIndex][shiftKey] = shift[i];
        }
        return;
    }
    if (toObj.payload.to.method === "multiMove") {
        const array = toObj.array;
        const driverIndex = array.findIndex(el => el.id == toObj.payload.to.driverId);
        const shift = toObj.payload.shift;
        const removedriverIndex = array.findIndex(el => el.id == toObj.payload.to.oldDriverId);
        for (let i = 0; i < shift.length; i++) {
            const shiftKey = `shift_${shift[i].startTime.toFixed(2)}`;
            delete array[removedriverIndex][shiftKey];
            array[driverIndex][shiftKey] = shift[i];
        }
        return;
    }
    if (fromObj.moveOpenToShift.openToShift === "openToShift") {
        let array = toObj.array;
        let shift = toObj.data.shift;
        let openShifts = data.openShifts;
        const driverId = shift.map((e) => e.driverId)[0];
        const driverIndex = array.findIndex(el => el.id == driverId);
        let dataIds = shift.map(item => item.routeId);
        for (let i = 0; i < shift.length; i++) {
            const shiftKey = `shift_${shift[i].startTime.toFixed(2)}`;
            openShifts = openShifts?.filter(obj => !dataIds.includes(obj.routeId));
            array[driverIndex][shiftKey] = shift[i];
            data.openShifts = openShifts;
        }
        return;
    }
    if (toObj.payload.to.target === "moveShiftToOpen") {
        let shift = fromObj.data.shift;
        let array = fromObj.array;
        let updateArray = toObj.array;
        let shiftIds = shift.map(shiftObj => shiftObj.routeId);
        array.forEach(obj => {
            Object.keys(obj).forEach(key => {
                if (key.startsWith("shift_")) {
                    let shiftId = obj[key].routeId;
                    if (shiftIds.includes(shiftId)) {
                        delete obj[key];
                    }
                }
            });
        });
        updateArray.push(...shift);
        return;
    }
    if (toObj.payload.to.target === "copyShiftToOpen") {
        data.openShifts.push(...toObj.data);
        return;
    }
    if (fromObj.moveOpenToShift.openToShift === "copyOpenToShift") {
        const shift = toObj?.data?.shift;
        const driverId = toObj?.data?.driverId;
        const array = data?.driversShifts;
        for (let i = 0; i < shift.length; i++) {
            let index = array.findIndex(obj => obj.id === driverId);
            if (index !== -1) {
                const shiftKey = `shift_${shift[i].startTime.toFixed(2)}`;
                array[index][shiftKey] = shift[i];
            }
        }
        return;
    }
    if (actions[fromObj.action]) actions[fromObj.action](fromObj.array, 'REMOVE', fromObj.data);
    if (actions[toObj.action]) actions[toObj.action](toObj.array, 'ADD', toObj.data);

    return true;
}

const actions = {
    openShift: (array,type,shift={}) => {
        switch (type) {
            case 'ADD':
                return array.push(shift);
            break;
            case 'REMOVE':
                const shiftIndex = array.findIndex(obj => obj.id === shift.id);
                return array.splice(shiftIndex,1);
            break;
        }
    },
    driverShift: (array,type,data) => {
        const driverIndex = array.findIndex(el => el.id == data.driverId);
        if(driverIndex < 0) return false;
        // const timeArray = String(data.startTime).split('.');
        switch(type){
            case 'ADD':                
              return array[driverIndex][`shift_${data.startTime.toFixed(2)}`] = data.shift;
            break;
            case 'REMOVE':
                let shifts = Object.keys(array[driverIndex]).filter(obj => obj.startsWith('shift_'));
                shifts.forEach(shift => {
                    if (array[driverIndex][shift].routeId === data.shift.routeId)
                        delete array[driverIndex][shift];
                })
                return array;
            break;
        }
    },
    backupDriver: (array,type,data) => {
        const driverIndex = array.findIndex(el => el.id === data.driverId);
        if(driverIndex < 0) return false;
        switch(type){
            case 'ADD':
                return array[driverIndex].shifts.push(data.shift);
            break;
            case 'REMOVE':
                const shiftIndex = array[driverIndex].shifts.findIndex(el => { return el.routeId === data.shift.routeId});
                if(shiftIndex < 0) return false;
                return array[driverIndex].shifts.splice(shiftIndex,1);
            break;
        }
    }
}

const Filter = (state,filter) => {
    let drivers = [...state];

    if(filter.driver && filter.driver.length > 0){
        drivers = drivers.filter(item => {
            if (!item.id)
                return false;

            return filter.driver.includes(item.id.toString());
        })
    }

    if(filter.stations){
        drivers = drivers.filter(item => {
            if (!item.stationId)
                return false;

            return filter.stations.toString() === item.stationId.toString();
        })
    }

    if(filter.schedules && filter.schedules.length > 0){
        drivers = drivers.filter(item => {
            if (!item.schedules)
                return false;

            return item.schedules.filter(s => filter.schedules.includes(s.toString())).length > 0;
        })
    }

    if (filter.overtime && filter.overtime.length > 0) {
        drivers = drivers.filter(item => {
            let avoidOvertime = [];
            filter.overtime.map(typeOvertime => {
                switch(typeOvertime){
                    case 'in_overtime':
                        avoidOvertime.push(item.workHours > 40);
                        break;
                    case 'scheduled':
                        avoidOvertime.push(item.estimatedWorkHours > 40);
                        break;
                    case 'not_scheduled_overtime':
                        avoidOvertime.push(item.estimatedWorkHours <= 40);
                        break;
                }
            })

            return avoidOvertime.includes(true);
        })
    }

    if (filter.skills && filter.skills.length > 0) {

        drivers = drivers.filter(item => {
            let skillExist = [];
            filter.skills.forEach(skill => {
                skillExist.push(item?.skills?.includes(parseInt(skill)));
            })

            return skillExist.includes(true);
        })
    }

    let filtered = drivers;

    if(filter.shift_type && filter.shift_type.length > 0){
        filtered = filtered.map(item => {
            let copy = {...item};
            Object.keys(copy)
                .filter(el => el.startsWith('shift_'))
                .forEach(shift => {
                    if ( !filter.shift_type.includes(copy[shift].typeId.toString()) )
                        delete copy[shift];
                });
            return copy
        })
    }

    if(!filter.unscheduled){
        filtered = filtered.filter(item => {
            return Object.keys(item).filter(el => el.startsWith('shift_')).length > 0;
        });
    }  else {
        filtered = filtered.filter(item => {
            if( item.employeeStatus == true )
                return Object.keys(item);
        });
    }

    return filtered;
}

const FilterTop = (state,filter,type) => {
    let drivers = [...state];

    if(filter.driver && filter.driver.length > 0){
        drivers = drivers.filter(item => {
            if(type === 'open'){
                if (!item.driverId)
                    return false;

                return filter.driver.includes(item.driverId.toString());
            }else{
                if (!item.id)
                    return false;

                return filter.driver.includes(item.id.toString());
            }

        })
    }

    if(filter.stations){
        drivers = drivers.filter(item => {

            if (!item.stationId)
                return false;

            return filter.stations.toString() === item.stationId.toString();
        })
    }

    if(filter.schedules && filter.schedules.length > 0){
        if(type === 'open'){
            drivers = drivers.filter(item => {
                if (!item.schedules)
                    return false;

                return item.schedules.filter(s => filter.schedules.includes(s.toString())).length > 0;
            })
        }else {
            drivers = drivers.map(driver => {
                const d = {...driver};
                d.shifts = [];
                return d;
            },[])


        }
    }

    if (filter.overtime && filter.overtime.length > 0) {
        if(type === 'open'){
            drivers = []
        }else{
            drivers = drivers.filter(item => {

            })
        }
    }
    let filtered = drivers;

    if(filter.shift_type && filter.shift_type.length > 0){
        if(type === 'open') {
            filtered = filtered.filter(item => {
                return filter.shift_type.includes(item.typeId.toString())
            })
        }else{
            filtered = filtered.filter(item => {
                let avoidOvertime = [];
                filter && filter.overtime && filter.overtime.length > 0 &&
                    filter.overtime.map(typeOvertime => {
                        switch (typeOvertime) {
                            case 'in_overtime':
                                avoidOvertime.push(item.workHours > 40);
                                break;
                            case 'scheduled':
                                avoidOvertime.push(item.estimatedWorkHours > 40);
                                break;
                            case 'not_scheduled_overtime':
                                avoidOvertime.push(item.estimatedWorkHours <= 40);
                                break;
                        }
                    })

                return avoidOvertime.includes(true);
            })
        }
    }

    if(!filter.unscheduled){
        if(type === 'open') {
            filtered = filtered.filter(item => {
                if (!item.unscheduled) return true;
                return false;
            });
        }
    }

    return filtered;
}

const order = (array,fields) => {
    const {mainFields,childFields} = fields;
    let dataKey = [];
    let mainResult = array.sort((a, b) => {
        a.shiftType = Object.keys(a).filter(p => p.startsWith('shift_')).map(shift => a[shift].type).join('-');
        a.routeCode = Object.keys(a).filter(p => p.startsWith('shift_')).map(shift => a[shift].routeCode.join('-')).join('-');
        const sortstr1 = mainFields.reduce((str,key) => {
            let c = '';
            let r = `${str}~${a[key]}`;
            if(childFields){
                c = a[dataKey].reduce((string,data)=> `${string}~${data[childFields[0]]}`,'')
            }
            return c+r;
        },'')
        const sortstr2 = mainFields.reduce((str,key) =>{
            let c = ''
            let r = `${str}~${b[key]}`
            if(childFields){
                c = b[dataKey].reduce((string,data)=> `${string}~${data[childFields[0]]}`,'')
            }
            return c+r;
        },'')

        return sortstr1.localeCompare(sortstr2)
    })

    if(childFields){
        mainResult = mainResult.map(item=>{

           let cc= item[dataKey].sort((c,d)=>{
                const str1 = childFields.reduce((string,fieldName) => `${string}~${c[fieldName]}`,'')
                const str2 = childFields.reduce((string,fieldName) => `${string}~${d[fieldName]}`,'')
                return str1.localeCompare(str2,{numeric: true});
            })

            return item;
        })
    }
    return mainResult;
}

const moveShifts = (array,source,target,data,hasAlert) => {

    const [idSource, weekSource, indexSource] = source;
    const [idTarget, weekTarget] = target;
    const targetRow = array.filter(item => {
        return item.id ==  idTarget;
    });

    let sourceRow;
    if(idTarget === idSource){
        sourceRow = targetRow;
    }else{
        sourceRow = array.filter(item => {
            return item.id ==  idSource;
        });
    }
    if(!targetRow) return false;



    data.alert = hasAlert;
    targetRow[0][weekTarget].push(data);
    if(idTarget === '0.0.0'){

        targetRow[0].groupQty = solveMaxOpenShift(targetRow[0]);
    }
    targetRow[0].biggerWeek = solveMax(targetRow[0]);

    sourceRow[0][weekSource].splice(indexSource,1);
    if(idTarget !== idSource){
        sourceRow[0].biggerWeek = solveMax(sourceRow[0]);
    }

    return true;
}

const solveMax = (row) => {

   let dataLengths = [];
   for(let i=0;i<=6;++i){
       dataLengths.push(row[`w${i}`].length);
   }

   return Math.max.apply(null,dataLengths);
}

const solveMaxOpenShift = (row) => {
    let dataLengths = [];
    for(let i=0;i<=6;++i){
        const reduced = row[`w${i}`].reduce((newArray,item)=>{
            const key = `${item.typeId}.${item.startTime}.${item.endTime}`;

            if(!newArray[key]){
                newArray[key] = {id:1};
            }
            return newArray
        },[])
        dataLengths.push(Object.values(reduced).length);
    }

    return Math.max.apply(null,dataLengths);
}

export default weekReducer;
